import React, { createElement, useRef, createRef, useEffect, ReactElement } from 'react';
import '@left4code/tw-starter/dist/js/modal';

interface NotificationProps {
  className?: string;
  options?: any;
  getRef: (current: React.ReactElement | null) => void;
  children?: React.ReactNode;
  type?: any;
  data?: any;
  width?: number;
  height?: number;
  callback?: () => void;
}

const Notification = (props: NotificationProps) => {
  const initialRender = useRef(true);
  const toastifyRef = createRef<ReactElement>();

  useEffect(() => {
    if (initialRender.current) {
      props.getRef(toastifyRef.current);
      // init(toastifyRef.current, props);
      initialRender.current = false;
    } else {
      // reInit(toastifyRef.current);
    }
  }, [props, toastifyRef]);

  const { type, data, options, width, height, getRef, ...computedProps } = props;
  return createElement(
    'div',
    {
      ...computedProps,
      className: `toastify-content hidden ${props.className}`,
      ref: toastifyRef
    },
    props.children
  );
};

export default Notification;
