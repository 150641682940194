import { createRoot } from 'react-dom/client';
import './assets/css/app.css';
import App from './App';
import React from 'react';
import './configureAmplify';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

root.render(<App />);
