import chatRoomsSlice from './chat-rooms/chat-rooms-slice';
import { combineReducers } from '@reduxjs/toolkit';
import chatRoomSlice from './chat-room/chat-room-slice';

// Combine all reducers about chat
const chatReducer = combineReducers({
  chatRooms: chatRoomsSlice.reducer,
  chatRoom: chatRoomSlice.reducer
});

export default chatReducer;
