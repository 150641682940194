import { Amplify } from 'aws-amplify';
import config from './aws-exports';

Amplify.configure(config);

export const configureLocalStorage = () => {
  const { Auth } = Amplify;
  Auth.configure({
    storage: window.localStorage
  });
};

export const configureSessionStorage = () => {
  const { Auth } = Amplify;
  Auth.configure({
    storage: window.sessionStorage
  });
};
