import React, { useState } from 'react';

import Help from '../components/DropdownHelp';
import UserMenu from './DropdownProfile';
import ThemeToggle from '../components/ThemeToggle';
import { Link } from 'react-router-dom';
import logo from '@/assets/images/logo_hutech.png';
import DropdownNotifications from '@/components/DropdownNotifications';

const Header = () => {
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  return (
    <header className='sticky top-2.5 z-30 mx-10 rounded-2xl border-b border-slate-200/60 bg-white px-1 shadow-xl dark:border-slate-700/60 dark:bg-[#182235] md:py-4'>
      <div className='px-4 lg:px-8'>
        <div className='-mb-px flex h-16 items-center justify-between'>
          {/* Header: Left side */}
          <Link className='flex space-x-1' to='/'>
            <img className='h-12 w-auto' src={logo} alt='logo' />
            <p className='hidden text-3xl font-bold text-sky-600 md:block'>HUTECH</p>
          </Link>

          {/* Header: Right side */}
          <div className='flex items-center space-x-3'>
            {/*<div>*/}
            {/*  <button*/}
            {/*    className={`ml-3 flex h-8 w-8 items-center justify-center rounded-full bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 ${*/}
            {/*      searchModalOpen && 'bg-slate-200'*/}
            {/*    }`}*/}
            {/*    onClick={(e) => {*/}
            {/*      e.stopPropagation();*/}
            {/*      setSearchModalOpen(true);*/}
            {/*    }}*/}
            {/*    aria-controls='search-modal'>*/}
            {/*    <span className='sr-only'>Search</span>*/}
            {/*    <svg className='h-4 w-4' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>*/}
            {/*      <path*/}
            {/*        className='fill-current text-slate-500 dark:text-slate-400'*/}
            {/*        d='M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z'*/}
            {/*      />*/}
            {/*      <path*/}
            {/*        className='fill-current text-slate-400 dark:text-slate-500'*/}
            {/*        d='M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z'*/}
            {/*      />*/}
            {/*    </svg>*/}
            {/*  </button>*/}
            {/*  /!*<SearchModal id='search-modal' searchId='search' modalOpen={searchModalOpen} setModalOpen={setSearchModalOpen} />*!/*/}
            {/*</div>*/}
            <DropdownNotifications align='right' />
            <Help align='right' />
            <ThemeToggle />
            {/*  Divider */}
            <hr className='h-6 w-px border-none bg-slate-200 dark:bg-slate-700' />
            <UserMenu align='right' />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
