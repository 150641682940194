import * as _ from 'lodash';

type IObject = {
  [key: string]: any;
};

export const cloneObject = <S extends IObject, T extends IObject>(
  source: S,
  sourceKeys: string[],
  targetKeys: string[],
  excludeKeys?: string[]
): T => {
  // T is an object with a subset of the keys of S
  // For each key in T, get the value from S (if it exists)
  // and assign it to the new object
  return targetKeys.reduce((acc, key) => {
    if (sourceKeys.includes(key) && (!excludeKeys || !excludeKeys.includes(key))) {
      // @ts-ignore
      acc[key] = _.cloneDeep(source[key]);
    }
    return acc;
  }, {} as T);
};
