import React from 'react';
import chatbotImg from '../../assets/images/chatbot.jpg';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { useAppSelector } from '@/stores/redux/hooks';

type MessagesHeaderProps = {
  msgSidebarOpen: boolean;
  setMsgSidebarOpen: (msgSidebarOpen: boolean) => void;
  chatRoomName: string;
};
const MessagesHeader = ({ msgSidebarOpen, setMsgSidebarOpen, chatRoomName }: MessagesHeaderProps) => {
  const { user } = useAuth();
  const currentChatRoom = useAppSelector((state) => state.chat.chatRoom);

  return (
    <>
      {/* Conditionally render the MessagesHeader component */}
      {!msgSidebarOpen && (
        <div className='sticky top-0 z-10 bg-white dark:bg-slate-800'>
          {currentChatRoom && (
            <div className='flex h-16 items-center justify-between border-b border-slate-200 bg-slate-50 px-4 dark:border-slate-700 dark:bg-[#161F32] sm:px-6 md:px-5'>
              {/* People */}
              <div className='flex items-center'>
                {/* Close button */}
                <button
                  className='mr-4 text-slate-400 hover:text-slate-500 md:hidden'
                  onClick={() => setMsgSidebarOpen(!msgSidebarOpen)}
                  aria-controls='messages-sidebar'
                  aria-expanded={msgSidebarOpen}>
                  <span className='sr-only'>Close sidebar</span>
                  <svg className='h-6 w-6 fill-current' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z' />
                  </svg>
                </button>
                {/* People list */}
                <div className='-ml-px flex space-x-3 align-middle'>
                  {chatRoomName && (
                    <img
                      className='box-content rounded-full border-2 border-white dark:border-slate-800'
                      src={chatbotImg}
                      width='50'
                      height='50'
                      alt='HUTECH Chatbot'
                    />
                  )}
                  <div className='flex flex-col justify-center'>
                    <div className='w-52 truncate text-base md:text-lg font-bold text-slate-800 dark:text-slate-100 md:w-fit'>
                      {chatRoomName ? chatRoomName : ''}
                      <div className='truncate text-xs text-slate-500 dark:text-slate-400'>
                        {chatRoomName ? `Hi ${user?.firstName}! Tôi là chatbot của HUTECH.` : ''}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Menu */}
                <div className='ml-4 shrink-0'></div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MessagesHeader;
