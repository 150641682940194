import { DropDown, DropdownContent, DropdownItem, DropdownMenu, DropdownToggle } from '../../base-components/DropDown';
import Lucide from '../../base-components/Lucide';
import React, { Fragment, useCallback, useState } from 'react';
import { friendlyTimestamp } from '@/utils/friendly_timestamp';
import { IMessage } from '@/types/IMessage';
import chatbotImg from '@/assets/images/chatbot.jpg';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface IChatbotMessageProps {
  message: IMessage;
  photo: string;
}

const ChatbotMessage = ({ message, photo }: IChatbotMessageProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(() => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, []);

  return (
    <Fragment>
      <div className='float-left mb-4 flex items-end last:mb-0'>
        <img className='mr-4 scale-125 rounded-full' src={chatbotImg} alt='Chatbot' width='40' height='40' />
        <div>
          <div className='mb-1 rounded-lg rounded-tl-none border border-slate-200 bg-white p-3 text-sm text-slate-800 shadow-md dark:border-slate-700 dark:bg-slate-800 dark:text-slate-100'>
            <div className='text-justify text-sm'>
              {message.text.split(/\s+/).map((word, i) => {
                if (word.match(/^(https?:\/\/|www\.)/i) || word.match(/[[{(]https?:\/\/\S+[\]})]/i) || word.match(/https?:\/\/\S+\.\S+/i)) {
                  // If the word is a URL, remove the parentheses or square brackets around it
                  const cleanWordStart = word.replace(/[[{(]/g, '');
                  const cleanWordEnd = cleanWordStart.replace(/[\]})]/g, '. ');
                  let url = '';
                  // if the word contains '. ', split it into an array and render each element separately
                  if (cleanWordEnd.includes('. ')) {
                    const splitWord = cleanWordEnd.split('. ');
                    url = splitWord[0];
                  } else {
                    url = cleanWordEnd;
                  }

                  return (
                    <React.Fragment key={i}>
                      <a href={url} target='_blank' rel='noopener noreferrer' className='text-blue-500 hover:underline'>
                        {url}
                      </a>{' '}
                    </React.Fragment>
                  );
                } else if (
                  word.match(/^(tel:|mailto:)/i) ||
                  word.match(/[[{(](tel:|mailto:\S+)[\]})]/i) ||
                  word.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$|^[\w-.]+@([\w-]+\.)+[\w-]{2,4}(?=\s|$)/) ||
                  word.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/) ||
                  word.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$|^[\w-.]+@([\w-]+\.)+[\w-]{2,}(?=\.|\s|$)/)
                  // // edu email
                  // word.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,}\.edu$|^[\w-.]+@([\w-]+\.)+[\w-]{2,}\.edu(?=\s|$)/) ||
                  // word.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,}\.edu$/)
                ) {
                  // If the word is a URL, remove the parentheses or square brackets around it
                  // If the email start with 'mailto:', remove it
                  const cleanWordStart = word.replace(/[[{(]/g, '');
                  const cleanWordEnd = cleanWordStart.replace(/[\]})]/g, '. ');
                  let url = '';
                  // if the word contains '. ', split it into an array and render each element separately
                  if (cleanWordEnd.includes('. ')) {
                    const splitWord = cleanWordEnd.split('. ');
                    url = splitWord[0] ? splitWord[0] : splitWord[1];
                  } else {
                    url = cleanWordEnd;
                  }

                  return (
                    <CopyToClipboard key={i} text={url} onCopy={handleCopy}>
                      <a key={i} href={url} target='_blank' rel='noopener noreferrer' className='text-blue-500' onClick={(e) => e.preventDefault()}>
                        {url}{' '}
                      </a>
                    </CopyToClipboard>
                  );
                } else if (word.includes('\n')) {
                  // If the word includes a line break, split it into separate words and wrap each one in a text node
                  const words = word.split('\n');
                  return words.map((w, j) => <React.Fragment key={`${i}-${j}`}>{w} </React.Fragment>);
                } else {
                  // Otherwise, return the word as a text node wrapped in a React.Fragment
                  return <React.Fragment key={i}>{word} </React.Fragment>;
                }
              })}
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <div className='text-xs font-medium text-slate-500'>{friendlyTimestamp(message.timestamp)}</div>
            <svg className='h-3 w-5 shrink-0 fill-current text-emerald-500' viewBox='0 0 20 12'>
              <path d='M10.402 6.988l1.586 1.586L18.28 2.28a1 1 0 011.414 1.414l-7 7a1 1 0 01-1.414 0L8.988 8.402l-2.293 2.293a1 1 0 01-1.414 0l-3-3A1 1 0 013.695 6.28l2.293 2.293L12.28 2.28a1 1 0 011.414 1.414l-3.293 3.293z' />
            </svg>
          </div>
        </div>
      </div>
      <div className='clear-both'></div>
    </Fragment>
  );
};

export default ChatbotMessage;
