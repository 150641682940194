import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import logo from '../assets/images/logo_hutech.png';
import AuthImage from '../assets/images/hutech-bg.jpg';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { Spinner } from 'react-bootstrap';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsConfig from '../aws-exports';
import { configureLocalStorage } from '@/configureAmplify';
import Lucide from '@/base-components/Lucide';

Amplify.configure(awsConfig);
const SignUpPage = () => {
  const { isSignedIn, signIn, user, successMessage, errorMessage, setSuccessMessage, setErrorMessage } = useAuth();
  const navigate = useNavigate();

  // Social sign in
  const [socialUser, setSocialUser] = useState(null);
  const [customState, setCustomState] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setSocialUser(data);
          break;
        case 'signOut':
          setSocialUser(null);
          break;
        case 'customOAuthState':
          setCustomState(data);
      }
    });

    getUser().then((r) => console.log('user: ', r));

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      navigate('/');
    }
  }, [isSignedIn]);

  const getUser = async (): Promise<void> => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setSocialUser(currentUser);
      console.log('social user: ', currentUser);
    } catch (error) {
      console.error(error);
      console.log('Not signed in');
    }
  };

  const handleSignInSocial = async (provider: string) => {
    try {
      if (provider === 'facebook') {
        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
      } else if (provider === 'google') {
        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
        console.log('Signed in with Google');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.classList.remove('main', 'error-page');
      body.classList.add('login');
    }
  }, []);

  const initialValues = {
    email: '',
    password: '',
    remember: true
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email không hợp lệ').required('Email không được để trống'),
    password: Yup.string().required('Mật khẩu không được để trống').min(8, 'Mật khẩu phải có ít nhất 8 ký tự'),
    remember: Yup.boolean()
  });

  const handleSubmit = async (values: any) => {
    const { email, password, remember } = values;
    console.log('values: ', values);

    if (!email || !password) {
      return;
    }

    // Configure local storage
    console.log('Configuring for remembering user');
    configureLocalStorage();

    const user = await signIn(email, password, remember);
    if (user) {
      console.log('Signed in');
      console.log(user);

      // Navigate to home page
      navigate('/');
    } else {
      console.error('Sign in failed');
    }
  };

  // Reset errorMessage and successMessage on unmount
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  return (
    <main className='bg-white dark:bg-slate-900 h-screen overflow-y-auto scroll-smooth'>
      <div className='relative md:flex'>
        {/* Content */}
        <div className='md:w-1/2'>
          <div className='flex h-full min-h-[100dvh] flex-col after:flex-1'>
            {/* Header */}
            <div className='flex-1'>
              <div className='flex h-16 items-center justify-between px-4 sm:px-6 lg:px-8'>
                {/* Logo */}
                <Link className='flex space-x-1' to='/'>
                  <img className='h-12 w-auto' src={logo} alt='logo' />
                  <p className='hidden text-4xl font-bold text-sky-600 dark:text-sky-300 md:block'>HUTECH</p>
                </Link>
              </div>
            </div>

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className='mx-auto w-full max-w-sm px-4 py-8'>
                  <h1 className='mb-6 text-4xl font-bold text-sky-700 dark:text-sky-300'>
                    Trải nghiệm <br /> HUTECH Chatbot ✨
                  </h1>
                  {successMessage && (
                    <div className='mt-4 flex items-center text-center text-green-500'>
                      <Lucide icon='CheckCircle' className='mr-2 text-green-500' />
                      <div className='text-sm'>{successMessage}</div>
                    </div>
                  )}
                  {errorMessage && (
                    <div className='mt-4 flex items-center text-center text-red-500'>
                      <Lucide icon='XCircle' className='mr-2 text-red-500' />
                      <div className='text-sm'>{errorMessage}</div>
                    </div>
                  )}
                  {/* Form */}
                  <div className='space-y-4'>
                    <div>
                      <label className='text-md mb-1 block font-medium' htmlFor='email'>
                        Email <span className='text-rose-500'>*</span>
                      </label>
                      <Field
                        name='email'
                        className='form-input w-full -space-y-px rounded-md shadow-sm dark:bg-slate-800'
                        type='email'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('email', e.target.value);
                        }}
                      />
                      <ErrorMessage name='email'>{(msg: string) => <span className='block px-2 py-1 text-red-600'>{msg}</span>}</ErrorMessage>
                    </div>
                    <div>
                      <label className='text-md mb-1 block font-medium' htmlFor='password'>
                        Mật khẩu
                      </label>
                      <Field
                        name='password'
                        className='form-input w-full -space-y-px rounded-md shadow-sm dark:bg-slate-800'
                        type='password'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('password', e.target.value);
                        }}
                      />
                      <ErrorMessage name='password'>{(msg: string) => <span className='block px-2 py-1 text-red-600'>{msg}</span>}</ErrorMessage>
                    </div>
                  </div>
                  <div className='mt-6 flex items-center justify-between'>
                    <div className='mr-1'>
                      <label className='flex items-center'>
                        <input type='checkbox' className='form-checkbox' />
                        <span className='ml-2 text-sm'>Nhớ mật khẩu</span>
                      </label>
                    </div>
                    {!isSubmitting ? (
                      <button
                        className='btn ml-3 whitespace-nowrap bg-blue-500 text-white hover:bg-blue-800 dark:bg-slate-600 dark:hover:bg-slate-800'
                        type='submit'>
                        Đăng nhập
                      </button>
                    ) : (
                      <button
                        className='btn disabled ml-3 whitespace-nowrap bg-blue-200 text-white cursor-not-allowed dark:bg-slate-400'
                        type='button'>
                        Đăng nhập
                      </button>
                    )}
                  </div>
                  {/*<div className='mt-6'>*/}
                  {/*  <button*/}
                  {/*    type='button'*/}
                  {/*    className='inline-flex w-full items-center justify-center gap-2 rounded-md border bg-white px-4 py-3 align-middle text-sm font-medium text-gray-700 shadow-sm transition-all hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-slate-800 dark:hover:text-white dark:focus:ring-offset-gray-800'*/}
                  {/*    onClick={() => handleSignInSocial('google')}>*/}
                  {/*    <svg className='h-auto w-4' width='46' height='47' viewBox='0 0 46 47' fill='none'>*/}
                  {/*      <path*/}
                  {/*        d='M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z'*/}
                  {/*        fill='#4285F4'*/}
                  {/*      />*/}
                  {/*      <path*/}
                  {/*        d='M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z'*/}
                  {/*        fill='#34A853'*/}
                  {/*      />*/}
                  {/*      <path*/}
                  {/*        d='M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z'*/}
                  {/*        fill='#FBBC05'*/}
                  {/*      />*/}
                  {/*      <path*/}
                  {/*        d='M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z'*/}
                  {/*        fill='#EB4335'*/}
                  {/*      />*/}
                  {/*    </svg>*/}
                  {/*    Sign in with Google*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                  {/* Footer */}
                  <div className='mt-6 border-t border-slate-200 pt-5 dark:border-slate-700'>
                    <div className='text-sm'>
                      Bạn chưa có tài khoản?{' '}
                      <Link className='font-medium text-blue-700 hover:font-bold hover:text-blue-700 dark:text-sky-300' to='/oauth/signup'>
                        Đăng ký ngay
                      </Link>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {/* Image */}
        <div className='absolute inset-y-0 right-0 hidden md:block md:w-1/2' aria-hidden='true'>
          <img className='h-full w-full object-cover object-center' src={AuthImage} width='760' height='1024' alt='Authentication' />
          <img
            className='absolute left-0 top-1/4 hidden -translate-x-1/2 lg:block'
            src={logo}
            width='150'
            height='160'
            alt='Authentication decoration'
          />
        </div>
      </div>
    </main>
  );
};

export default SignUpPage;
