// React Router
import { useRoutes } from 'react-router-dom';
import React from 'react';
import HomePage from '@/views/HomePage';
import SignUpPage from '@/views/SignUpPage';
import SignInPage from '@/views/SignInPage';
import ChatPageDev from '@/views/ChatPageDev';
import CodeVerificationPage from '@/views/CodeVerificationPage';
import AdminPage from '@/views/AdminPage';
import SideMenu from '@/layouts/SideMenu';

const Router = () => {
  const routes = [
    {
      path: '/',
      element: <SideMenu />,
      children: [
        {
          path: '/',
          element: <ChatPageDev />
        },
        {
          path: '/chat',
          element: <ChatPageDev />
        },
        {
          path: '/admin',
          element: <AdminPage />
        }
      ]
    },
    {
      path: '/oauth/code',
      element: <CodeVerificationPage />
    },
    {
      path: '/oauth/signin',
      element: <SignInPage />
    },
    {
      path: '/oauth/signup',
      element: <SignUpPage />
    }
  ];

  return useRoutes(routes);
};

export default Router;
