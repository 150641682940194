import { createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { IUserToken } from '@/types/IUserToken';
import { RootState } from '@/stores/redux/store';

export interface IUserTokenState {
  token: IUserToken;
}

const initialValues: IUserTokenState = {
  token: {
    id: '',
    totalTokenAmount: 0,
    spentTokenAmount: 0,
    userTokenUserId: ''
  }
};

const userTokenSlice = createSlice({
  name: 'userToken',
  initialState: initialValues,
  reducers: {
    setUserToken: (state: IUserTokenState, action: PayloadAction<IUserToken>) => {
      state.token = action.payload;
    },
    updateUserToken: (state: IUserTokenState, action: PayloadAction<Update<IUserToken>>) => {
      state.token = {
        ...state.token,
        ...action.payload.changes
      };
    },
    resetUserToken: () => {
      return initialValues;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userTokenActions.setUserToken, (state, action) => {
      console.log('Updated user token: ', action.payload);
    });
  }
});

export const selectUserToken = (state: RootState) => state.userToken.token;

export const userTokenActions = userTokenSlice.actions;
export default userTokenSlice;
