import { UseWhisperConfig, UseWhisperTranscript } from '@chengsokdara/use-whisper/dist/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const useWhisperInitialState: UseWhisperConfig = {
  apiKey: 'we_should_not_use_this',
  autoTranscribe: true,
  nonStop: true,
  removeSilence: true,
  stopTimeout: 3000,
  mode: 'transcriptions',
  whisperConfig: {
    prompt: 'Nội dung cuộc trò chuyện của sinh viên và nhà trường:\n',
    temperature: 0.2,
    language: 'vi'
  },
  onTranscribe: undefined
};

const whisperConfigSlice = createSlice({
  name: 'whisperConfig',
  initialState: useWhisperInitialState,
  reducers: {
    updateWhisperConfig: (state: UseWhisperConfig, action: PayloadAction<UseWhisperConfig>) => {
      return { ...state, ...action.payload };
    },
    setLanguage: (state: UseWhisperConfig, action: PayloadAction<string>) => {
      return { ...state, whisperConfig: { ...state.whisperConfig, language: action.payload } };
    },
    setOnTranscribe: (state: UseWhisperConfig, action: PayloadAction<(blob: Blob) => Promise<UseWhisperTranscript>>) => {
      return { ...state, onTranscribe: action.payload };
    }
  }
});

export const { updateWhisperConfig, setLanguage, setOnTranscribe } = whisperConfigSlice.actions;

export default whisperConfigSlice;
