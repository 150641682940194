import { cloneObject } from '@/utils/object_clone';
import { Message } from '@/API';
import { keys } from 'ts-transformer-keys';

// Add an 'ok' field of type boolean as optional
export type IMessage = Omit<
  Message,
  'updatedAt' | 'owner' | '_version' | '_deleted' | '_lastChangedAt' | '__typename' | 'user' | 'chatRoom' | 'ownerUserID'
> & {
  ok?: boolean;
  contentHash?: string;
};

export const cloneMessage = (message: Message): IMessage => {
  return cloneObject<Message, IMessage>(message, keys<Message>(), keys<IMessage>());
};

export const createEmptyMessage = (): IMessage => {
  return {
    id: '',
    createdAt: '',
    text: '',
    chatRoomID: '',
    userID: '',
    timestamp: '',
    completionTokens: 0,
    promptTokens: 0,
    isDefaultMessage: false,
    ok: true,
    contentHash: ''
  };
};
