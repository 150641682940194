import React, { useEffect, useRef, useState } from 'react';

import chatbotImg from '../../assets/images/logo_hutech.png';
import userImg from '../../assets/images/avatar-1.jpg';
import HumanMessage from '@/components/messages/HumanMessage';
import ChatbotMessage from '@/components/messages/ChatbotMessage';
import { selectActiveChatRoom, selectAllMessages } from '@/stores/redux/chat-room/chat-room-slice';
import { useAppSelector } from '@/stores/redux/hooks';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { IMessage } from '@/types/IMessage';

type MessageBodyProps = {
  messages: IMessage[];
};
const MessagesBody = () => {
  const inputEl = useRef<HTMLTextAreaElement>(null);
  const messages = useAppSelector((state) => selectAllMessages(state));
  const chatContentRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const activeChatRoom = useAppSelector(selectActiveChatRoom);
  const currentChatRoom = useAppSelector((state) => state.chat.chatRoom);

  // Scroll to bottom of chat content
  useEffect(() => {
    if (chatContentRef.current || (activeChatRoom.streamMessageID !== '' && chatContentRef.current)) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [messages, activeChatRoom]);

  return (
    <>
      {currentChatRoom && currentChatRoom.agentID && currentChatRoom.chatRoomName !== '' ? (
        <div
          className='scrollbar-hidden flex-1 overflow-y-scroll overscroll-y-contain bg-sky-100 px-4 py-6 dark:bg-slate-800 sm:px-6 md:px-5'
          ref={chatContentRef}>
          {messages.map((message, index) => {
            if (message.userID === user?.id) {
              return <HumanMessage key={index} message={message} photo={userImg} />;
            } else {
              return <ChatbotMessage key={index} message={message} photo={chatbotImg} />;
            }
          })}
        </div>
      ) : (
        <div className='intro-y flex h-full items-center justify-center'>
          <div className='w-52 truncate text-lg font-bold text-slate-800 dark:text-slate-100 md:w-fit text-center'>
            <p className='text-3xl font-bold'>Xin chào {user?.lastName + ' ' + user?.firstName} 👋</p>
            <p className='mt-1 text-gray-600'>
              Hãy bấm nút <a className='font-bold text-blue-500'>Chat với HUTECH ngay</a> để trò chuyện với HUTECH Chatbot ngay 🤖
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default MessagesBody;
