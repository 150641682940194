import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { DropDown, DropdownContent, DropdownItem, DropdownMenu, DropdownToggle } from '@/base-components/DropDown';
import Lucide from '@/base-components/Lucide';
import { useAppSelector } from '@/stores/redux/hooks';
import { selectAllUsers } from '@/stores/redux/users/users-slice';
import Pagination from '@/components/Pagination/Pagination';
import _, { ceil } from 'lodash';
import { IUser } from '@/types/IUser';
import { Modal, ModalBody, ModalHeader } from '@/base-components/modal';
import { Field, Form, Formik } from 'formik';
import classNames from 'classnames';
import avatarImg1 from '@/assets/images/avatar-1.jpg';
import avatarImg2 from '@/assets/images/avatar-2.png';
import avatarImg3 from '@/assets/images/avatar-3.png';
import avatarImg4 from '@/assets/images/avatar-4.png';
import avatarImg5 from '@/assets/images/avatar-5.png';
import avatarImg6 from '@/assets/images/avatar-6.png';
import avatarImg7 from '@/assets/images/avatar-7.png';

const AdminPage = () => {
  const users = useAppSelector((state) => selectAllUsers(state));

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [currentUsers, setCurrentUsers] = useState<IUser[]>([]);
  const [usersFilted, setUsersFilted] = useState<IUser[]>([]);
  const [, setSearchDropdown] = useState(false);
  const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
  const [modalUserID, setModalUserID] = useState('');

  // Render users per page
  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * usersPerPage;
    const lastPageIndex = firstPageIndex + usersPerPage;

    // If usersFilted is empty, render all users
    // Else, render usersFilted
    if (usersFilted.length === 0) {
      setCurrentUsers(users.slice(firstPageIndex, lastPageIndex));
    } else {
      setCurrentUsers(usersFilted.slice(firstPageIndex, lastPageIndex));
    }
  }, [currentPage, users, usersFilted, usersPerPage]);

  const showSearchDropdown = () => {
    setSearchDropdown(true);
  };

  const hideSearchDropdown = () => {
    setTimeout(() => {
      setSearchDropdown(false);
    }, 250);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchChange = useCallback(
    _.debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log(e.target.value);
      const searchValue = e.target.value;
      if (searchValue) {
        const filteredUsers = users.filter((user) => {
          // Filter by email
          const email_match = user.email.toLowerCase().includes(searchValue.toLowerCase());
          // Filter by first name
          const forward_name_match = (user.firstName + ' ' + user.lastName).toLowerCase().includes(searchValue.toLowerCase());
          // Filter by last name
          const back_name_match = (user.lastName + ' ' + user.firstName).toLowerCase().includes(searchValue.toLowerCase());

          return email_match || forward_name_match || back_name_match;
        });

        console.log('filteredUsers', filteredUsers);
        setUsersFilted(filteredUsers);
      } else {
        setUsersFilted([]);
      }
    }, 500),
    [users]
  );

  const handleSubmit = (values: any) => {
    console.log(values);
  };

  //random avatar image
  const imageCollection = [avatarImg1, avatarImg2, avatarImg3, avatarImg4, avatarImg5, avatarImg6, avatarImg7];
  const randomAvatarImg = () => {
    const randomIndex = Math.floor(Math.random() * imageCollection.length);
    return imageCollection[randomIndex];
  };

  return (
    <div className='intro-y flex flex-col sm:flex-row items-center mt-8 bg-blue-200 p-5'>
      {/* BEGIN: Modal Content */}
      <Modal
        size='modal-lg'
        show={headerFooterModalPreview}
        onHidden={() => {
          setModalUserID('');
          setHeaderFooterModalPreview(false);
        }}>
        <ModalHeader>
          <h2 className='mr-auto text-base font-medium'>Thêm Intelium</h2>
        </ModalHeader>
        <ModalBody>
          {/*{modalUserID ? <AddTokenForm userID={modalUserID} setHeaderFooterModalPreview={setHeaderFooterModalPreview} /> : <p>Waiting</p>}*/}
        </ModalBody>
      </Modal>
      {/* END: Modal Content */}
      <h2 className='intro-y mt-10 text-lg font-medium'>Cộng đồng người dùng</h2>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form className='mt-5 grid grid-cols-12 gap-6'>
          <div className='intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap'>
            <button className='btn btn-primary mr-2 shadow-md'>Thêm người dùng mới</button>
            <DropDown>
              <DropdownToggle className='btn box px-2'>
                <span className='flex h-5 w-5 items-center justify-center'>
                  <Lucide icon='Plus' className='h-4 w-4' />
                </span>
              </DropdownToggle>
              <DropdownMenu className='w-40'>
                <DropdownContent>
                  <DropdownItem>
                    <Lucide icon='Users' className='mr-2 h-4 w-4' /> Thêm nhóm mới
                  </DropdownItem>
                  <DropdownItem>
                    <Lucide icon='MessageCircle' className='mr-2 h-4 w-4' /> Gửi tin nhắn
                  </DropdownItem>
                </DropdownContent>
              </DropdownMenu>
            </DropDown>
            <div className='mx-auto hidden text-slate-500 md:block'>Hiển thị 1 - {currentUsers.length} người dùng</div>
            <div className='mt-3 w-full sm:mt-0 sm:ml-auto sm:w-auto md:ml-0'>
              <div className='relative w-56 text-slate-500'>
                <input
                  type='text'
                  className='form-control box w-56 pr-10'
                  placeholder='Tìm kiếm...'
                  onFocus={showSearchDropdown}
                  onBlur={hideSearchDropdown}
                  onChange={handleSearchChange}
                />
                <Lucide icon='Search' className='absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4' />
              </div>
            </div>
          </div>
          {/* BEGIN: Users Layout */}
          {currentUsers.map((user) => {
            return (
              <div key={user.id} className='intro-y col-span-12 md:col-span-6'>
                <div className='box'>
                  <div className='flex flex-col items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 lg:flex-row'>
                    <div className='image-fit h-24 w-24 lg:mr-1 lg:h-12 lg:w-12'>
                      <img alt='Milu chatbot' className='rounded-full' src={randomAvatarImg()} />
                    </div>
                    <div className='mt-3 text-center lg:ml-2 lg:mr-auto lg:mt-0 lg:text-left'>
                      <p className='font-medium'>{user.firstName + ' ' + user.lastName}</p>
                      <div className='mt-0.5 text-xs text-slate-500'>{user.email}</div>
                    </div>
                  </div>
                  <div className='flex flex-wrap items-center justify-center p-5 lg:flex-nowrap'>
                    <div className='mb-4 mr-auto w-full lg:mb-0 lg:w-1/2'>
                      <div className='flex text-xs text-slate-500'>
                        <div className='mr-auto'>Sử dụng</div>
                        <div>{user.userToken ? user.userToken.totalTokenAmount : 0}</div>
                      </div>
                      <div className='progress mt-2 h-3'>
                        <div
                          className={classNames('progress-bar', {
                            'w-full': user.userToken && ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) === 1,
                            'w-4/5':
                              user.userToken &&
                              ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) < 1 &&
                              ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) > 0.75,
                            'w-3/4':
                              user.userToken &&
                              ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) <= 0.75 &&
                              ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) > 0.5,
                            'w-1/2':
                              user.userToken &&
                              ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) <= 0.5 &&
                              ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) > 0.25,
                            'w-1/4':
                              user.userToken &&
                              ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) <= 0.25 &&
                              ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) > 0.12,
                            'w-1/8':
                              user.userToken &&
                              ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) <= 0.12 &&
                              ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) > 0,
                            'w-0': user.userToken && ceil(user.userToken.spentTokenAmount / user.userToken.totalTokenAmount, 1) === 0
                          })}
                          role='progressbar'
                          aria-valuenow={user.userToken ? user.userToken.spentTokenAmount : 0}
                          aria-valuemin={0}
                          aria-valuemax={user.userToken ? user.userToken.totalTokenAmount : 0}>
                          <a className='progress-bar-tooltip'>{user.userToken ? user.userToken.spentTokenAmount : 0}</a>
                        </div>
                      </div>
                    </div>
                    <button
                      className='btn btn-outline-secondary py-1 px-2'
                      type='button'
                      onClick={() => {
                        setModalUserID(user.id);
                        setHeaderFooterModalPreview(true);
                      }}>
                      Add Token
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          {/* END: Users Layout */}
          {/* BEGIN: Pagination */}
          <div className='intro-y col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap'>
            <Pagination
              usersPerPage={usersPerPage}
              currentPage={currentPage}
              data={currentUsers}
              total={usersFilted.length > 0 ? usersFilted.length : users.length}
              limit={usersPerPage}
              onPageChange={(page: any) => setCurrentPage(page)}
            />
            <Field
              as='select'
              name='perPage'
              value={usersPerPage}
              className='box form-select mt-3 w-20 sm:mt-0'
              onChange={(e: any) => {
                setUsersPerPage(e.target.value);
              }}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Field>
          </div>
          {/* END: Pagination */}
        </Form>
      </Formik>
    </div>
  );
};
export default AdminPage;
