import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import Header from '../components/Header';
import MessagesBody from '../components/messages/MessagesBody';
import MessagesFooter from '../components/messages/MessagesFooter';
import chatbotImg from '../assets/images/chatbot.jpg';
import MessagesHeader from '@/components/messages/MessagesHeader';
import { useAppDispatch, useAppSelector } from '@/stores/redux/hooks';
import { selectActiveChatRoom, selectAllMessages } from '@/stores/redux/chat-room/chat-room-slice';
import { useNavigate } from 'react-router-dom';
import { selectAllAgents } from '@/stores/redux/agents/agents-slice';
import { useChat } from '@/context/ChatProvider';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { ChatRoomSubscription } from '@/utils/graphql/chat_room_utils';
import { UserTokenSubscription } from '@/utils/graphql/user_token_utils';
import { Hub } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { gqlFetchAllAgentsAndDispatch } from '@/utils/graphql/agent_utils';
import { addChatRoom, selectAllChatRooms, setActiveChatRoom } from '@/stores/redux/chat-rooms/chat-rooms-slice';
import { MessageSubscription } from '@/utils/graphql/message_utils';
import { Modal } from '@/base-components/modal';
import SignInForm from '@/components/SignInForm';
import { IAgent } from '@/types/IAgent';
import { generate_uuid } from '@/utils/uuid_generator';
import axios from 'axios';
import { PlusCircle } from 'lucide-react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChatPageDevProps {
  // Props definition
}

const ChatPageDev: React.FC<ChatPageDevProps> = (props) => {
  const activeChatRoom = useAppSelector(selectActiveChatRoom);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const agents = useAppSelector((state) => selectAllAgents(state));
  const chatRooms = useAppSelector((state) => selectAllChatRooms(state));
  const currentChatRoom = useAppSelector((state) => state.chat.chatRoom);
  const { socket, isWaitingForResponse, sendMessage } = useChat();
  const { isSignedIn, user, isDeviceRemembered, updateDeviceKey, isAdmin } = useAuth();
  const currentChatRoomID = useAppSelector((state) => state.chat.chatRoom.id);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [chatContentHeight] = useState<string | number>('calc(100vh - 5rem)');

  const [msgSidebarMobileOpen, setMsgSidebarMobileOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const showSidebar = !isMobile || msgSidebarMobileOpen;

  //test if agents has been loaded
  useEffect(() => {
    if (agents.length > 0) {
      console.log('agents', agents);
    } else {
      console.log('agents not loaded');
    }
  }, [agents]);

  // Get the last message id of the current chat room
  useEffect(() => {
    const lastMessageID = activeChatRoom.ids[activeChatRoom.ids.length - 1];
    // if (lastMessageID) {
    //   setLastMessageID(lastMessageID);
    // }
    console.log('lastMessageID', lastMessageID);
  }, [activeChatRoom]);

  if (!socket || !user || !currentChatRoom.isReady || !currentChatRoom.id) {
    console.log('socket', socket);
  }

  const createChatRoomHandler = useCallback(
    async (agent: IAgent) => {
      if (!user) {
        console.warn('User is not logged in, this should not happen');
        return;
      }

      // Check if there is a chat room with the same agent
      const existingChatRoom = chatRooms.find((chatRoom) => chatRoom.agentID === agent.id);
      console.log('existingChatRoom', existingChatRoom);

      if (existingChatRoom) {
        console.log('Chat room already exists');
        dispatch(setActiveChatRoom(existingChatRoom.id));
        return;
      } else {
        // Create a chat room ID which is compatible with DynamoDB (UUID v4)
        const chatRoomID = generate_uuid();

        const ret = await axios.post(
          '/api/v1/chat_rooms/create',
          {
            chatRoomID: chatRoomID,
            userID: user.id,
            agentID: agent.id,
            totalQuestionAmount: 100,
            usingPeriod: 30
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.idToken}`
            },
            timeout: 10000
          }
        );

        if (ret.status === 201 && ret.data && ret.data.event === 'chatRoomCreated' && ret.data.data) {
          console.log('Chat room created successfully');
          dispatch(
            addChatRoom({
              ...ret.data.data
            })
          );

          // Set the active chat room to the newly created chat room
          dispatch(setActiveChatRoom(chatRoomID));
        }
      }
    },
    [user, chatRooms]
  );

  return (
    <>
      {/*  Site header */}
      <div className='grow my-4 md:my-5'>
        <div className='relative flex h-full bg-sky-200 dark:bg-slate-900 md:space-x-2 md:pb-2 md:pl-2'>
          {/* Messages sidebar for mobile */}
          {showSidebar && (
            <div
              id='messages-sidebar'
              className={`absolute inset-y-0 z-20 -mr-px w-full transition-transform duration-200 ease-in-out md:static md:inset-y-auto md:w-auto md:translate-x-0${
                msgSidebarMobileOpen ? 'translate-x-0' : '-translate-x-full'
              }`}>
              <div className='scrollbar-hidden sticky top-0 h-[calc(100dvh-130px)] shrink-0 overflow-y-auto overflow-x-hidden border-r border-slate-200 bg-white dark:border-slate-700 dark:bg-slate-900 md:w-72 xl:w-80'>
                {/* #Marketing group */}
                <div>
                  {/* Group header */}
                  <div className='sticky top-0 z-10 p-3'>
                    <div className='flex w-full justify-center py-2'>
                      <Menu as='div' className='relative inline-block text-left'>
                        <Menu.Button className='hover:border-1 flex h-12 items-center justify-center rounded-full bg-sky-50 p-2 shadow-md hover:border hover:border-blue-200 hover:bg-slate-50 hover:shadow-xl focus:bg-slate-50 focus:outline-none dark:border-none dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:bg-slate-700'>
                          <div className='flex items-center truncate'>
                            <div className='truncate'>
                              <span className='text-sm font-bold text-sky-600 hover:text-sky-700 dark:text-slate-100 dark:hover:text-slate-300'>
                                Chat với HUTECH ngay
                              </span>
                            </div>
                          </div>
                          <div className='ml-2 flex items-center'>
                            <PlusCircle className='h-6 w-6 text-sky-400' />
                          </div>
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'>
                          <Menu.Items className='absolute right-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-darkmode-700 dark:ring-darkmode-700'>
                            <div className='scrollable scrollbar-hidden max-h-44 overflow-y-auto overscroll-y-contain py-1'>
                              {agents.map((agent) => (
                                <React.Fragment key={agent.id}>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href='#'
                                        className={classNames(
                                          active || currentChatRoom.agentID === agent.id
                                            ? 'bg-gray-100 text-gray-900 dark:bg-darkmode-600 dark:text-slate-200'
                                            : 'text-gray-700 dark:text-slate-200',
                                          'block px-4 py-2 text-sm'
                                        )}
                                        onClick={() => {
                                          createChatRoomHandler(agent);
                                        }}>
                                        <div className='flex w-fit items-center'>{agent.name}</div>
                                      </a>
                                    )}
                                  </Menu.Item>
                                </React.Fragment>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    {/* Search form */}
                    <div className='relative'>
                      <label htmlFor='msg-search' className='sr-only'>
                        Search
                      </label>
                      <input id='msg-search' className='form-input w-full bg-white pl-9 dark:bg-slate-800' type='search' placeholder='Tìm kiếm...' />
                      <button className='group absolute inset-0 right-auto' type='submit' aria-label='Search'>
                        <svg
                          className='ml-3 mr-2 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 dark:text-slate-500 dark:group-hover:text-slate-400'
                          viewBox='0 0 16 16'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path d='M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z' />
                          <path d='M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z' />
                        </svg>
                      </button>
                    </div>
                    {/* Direct messages */}
                    <div className='mt-4'>
                      <div className='mb-3 text-xs font-semibold uppercase text-slate-400 dark:text-slate-500'>Direct messages</div>
                      <ul className='scrollbar-hidden mb-6 h-[calc(100vh-6rem)] space-y-2 overflow-y-auto overflow-x-hidden scroll-smooth px-2'>
                        {chatRooms.map((chatRoom) => (
                          <li className='-mx-2' key={chatRoom.id}>
                            <button
                              className={classNames(
                                currentChatRoom.id === chatRoom.id
                                  ? 'bg-gray-100 text-gray-800 dark:bg-slate-800 dark:text-slate-200'
                                  : 'bg-sky-100 dark:bg-slate-600 text-gray-600 dark:text-slate-200',
                                'flex w-full items-center justify-between rounded-full p-2 shadow-xl hover:bg-slate-50 focus:bg-slate-50 focus:outline-none dark:hover:bg-slate-800 dark:focus:bg-slate-800'
                              )}
                              onClick={() => {
                                setMsgSidebarMobileOpen(false);
                                dispatch(setActiveChatRoom(chatRoom.id));
                              }}>
                              <div className='flex items-center truncate'>
                                <img className='mr-2 h-8 w-8 rounded-full' src={chatbotImg} width='32' height='32' alt='User 01' />
                                <div className='truncate'>
                                  <span className='text-sm font-medium text-slate-800 dark:text-slate-100'>
                                    {agents.find((a) => a.id === chatRoom.agentID)?.name}
                                  </span>
                                </div>
                              </div>
                              {/*<div className='ml-2 hidden items-center'>*/}
                              {/*  <div className='inline-flex rounded-full bg-sky-400 px-2 text-center text-xs font-medium leading-5 text-white'>*/}
                              {/*    2*/}
                              {/*  </div>*/}
                              {/*</div>*/}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* Channels */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Messages body */}
          <div
            className={`flex grow h-screen flex-col transition-transform duration-300 ease-in-out md:translate-x-0 ${
              msgSidebarMobileOpen ? 'translate-x-full' : 'translate-x-0'
            }`}
            style={{ height: chatContentHeight }}>
            <MessagesHeader
              msgSidebarOpen={msgSidebarMobileOpen}
              setMsgSidebarOpen={setMsgSidebarMobileOpen}
              chatRoomName={agents.find((a) => a.id === currentChatRoom.agentID)?.name || ''}
            />
            <MessagesBody />
            <MessagesFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatPageDev;
