import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lucide from '@/base-components/Lucide';
import { useChat } from '@/context/ChatProvider';
import useWhisper from '@chengsokdara/use-whisper';
import classNames from 'classnames';
import { MicrophoneIcon } from '@heroicons/react/24/outline';
import UseAnimations from 'react-useanimations';
import activity from 'react-useanimations/lib/activity';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { useAppDispatch, useAppSelector } from '@/stores/redux/hooks';
import { setOnTranscribe } from '@/stores/redux/whisper/whisper-config-slice';
import { useMemoAsync } from '@chengsokdara/react-hooks-async';

const MessagesFooter = () => {
  const { user } = useAuth();
  const currentChatRoom = useAppSelector((state) => state.chat.chatRoom);
  const inputEl = useRef<HTMLTextAreaElement>(null);
  const { socket, isWaitingForResponse, sendMessage } = useChat();
  const whisperConfig = useAppSelector((state) => state.whisperConfig);
  const dispatch = useAppDispatch();

  const onWhispered = useMemoAsync(
    async (file: File) => {
      // Whisper only accept multipart/form-data currently
      const body = new FormData();
      body.append('file', file);
      body.append('model', 'whisper-1');
      body.append('language', whisperConfig.whisperConfig?.language ?? 'en');
      if (whisperConfig.whisperConfig?.prompt) {
        body.append('prompt', whisperConfig.whisperConfig.prompt);
      }
      if (whisperConfig.whisperConfig?.response_format) {
        body.append('response_format', whisperConfig.whisperConfig.response_format);
      }
      if (whisperConfig.whisperConfig?.temperature) {
        body.append('temperature', `${whisperConfig.whisperConfig.temperature}`);
      }
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user?.idToken ?? ''}`
      };
      const { default: axios } = await import('axios');
      console.log('MinhLuan: request to', '/api/v1/whisper/' + whisperConfig.mode, 'file size', file.size, 'bytes, file type', file.type);
      const response = await axios.post('/api/v1/whisper/' + whisperConfig.mode, body, {
        headers
      });
      console.log('MinhLuan: response', response);
      return response.data.text;
    },
    [whisperConfig.mode, whisperConfig.whisperConfig, user?.idToken]
  );

  /**
   * you have more control like this
   * do whatever you want with the recorded speech
   * send it to your own custom server
   * and return the response back to useWhisper
   */
  const onTranscribe = useCallback(
    async (blob: Blob) => {
      const file = new File([blob], 'speech.mp3', { type: 'audio/mpeg' });
      const text = await onWhispered(file);
      console.log('MinhLuan: onTranscribing', { text });

      return {
        blob,
        text
      };
    },
    [onWhispered]
  );

  useEffect(() => {
    // Set the onTranscribe callback
    dispatch(setOnTranscribe(onTranscribe));
  }, [onWhispered, onTranscribe, dispatch]);

  const { recording, transcript, startRecording, stopRecording } = useWhisper(whisperConfig);

  useEffect(() => {
    if (transcript.text) {
      inputEl.current!.value = transcript.text;
    }
  }, [transcript.text]);

  const handleKeyInput = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Check if enter key is pressed and there is no "ctrl" or "shift" key pressed
    if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault();
      if (isWaitingForResponse.current) {
        return;
      } else {
        const messageText = inputEl.current?.value.trim();
        if (messageText) {
          sendMessage(messageText);
        }
        inputEl.current!.value = '';
      }
    }
  };

  return (
    <div className='sticky bottom-3 md:bottom-0 bg-sky-200 dark:bg-slate-900'>
      {currentChatRoom && currentChatRoom.agentID && currentChatRoom.chatRoomName && (
        <div className='flex h-16 items-center justify-between border-t border-slate-200 bg-white px-4 align-middle dark:border-slate-700 dark:bg-slate-900 sm:px-6 md:mb-3 md:px-5'>
          {/* Plus button */}
          <button className='mr-3 shrink-0 text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400'>
            <span className='sr-only'>Add</span>
            <svg className='h-6 w-6 fill-current' viewBox='0 0 24 24'>
              <path d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.98 5.38 18.62.02 12 0zm6 13h-5v5h-2v-5H6v-2h5V6h2v5h5v2z' />
            </svg>
          </button>
          {/* Message input */}
          <div className='flex grow items-center'>
            <div className='mr-3 grow'>
              <textarea
                className='form-control mt-2 h-14 resize-none border-transparent px-1 shadow-none focus:border-transparent focus:ring-0 dark:bg-darkmode-600 md:px-5'
                rows={1}
                placeholder='Type your message...'
                onKeyPress={handleKeyInput}
                ref={inputEl}
              />
            </div>
            {/* Record button */}
            <a
              className={classNames(
                'mr-5 flex h-12 w-12 flex-none border border-1 border-blue-500 items-center justify-center rounded-full bg-gray-100 text-white transition duration-500 ease-in-out hover:bg-blue-300 hover:border-0 sm:h-10 sm:w-10',
                { 'bg-light-blue-600 animate-pulse': recording }
              )}>
              {!recording ? (
                <MicrophoneIcon
                  className='h-6 w-6 text-blue-600 transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-110 hover:text-red-500'
                  onClick={async () => {
                    // Clear the current transcript
                    inputEl.current!.value = '';
                    await startRecording();
                  }}
                />
              ) : (
                <UseAnimations
                  className='h-6 w-6 text-blue-500 transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-110 hover:text-red-500'
                  size={24}
                  pathCss={`
          stroke:  #1da1f2;
          stroke-width: 2;
          `}
                  animation={activity}
                  onClick={async () => {
                    await stopRecording();
                  }}
                />
              )}
            </a>
            {/* Send button */}
            <button
              type='submit'
              className='flex h-12 w-12 flex-none items-center justify-center rounded-full bg-blue-500 text-white sm:h-10 sm:w-10'
              onClick={(e) => {
                e.preventDefault();
                if (isWaitingForResponse.current) {
                  return;
                } else {
                  const messageText = inputEl.current?.value.trim();
                  if (messageText) {
                    sendMessage(messageText);
                  }
                  inputEl.current!.value = '';
                }
              }}>
              <Lucide icon='Send' className='h-4 w-4' />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessagesFooter;
