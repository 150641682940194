import { BrowserRouter } from 'react-router-dom';
import Router from './router/Router';
import { ErrorBoundary } from 'react-error-boundary';
import { Error, ErrorHandler } from '@/base-components/Error/Error';
import { AmplifyAuthProvider } from '@/context/AmplifyAuthProvider';
import { ChatProvider } from '@/context/ChatProvider';
import { Provider } from 'react-redux';
import store from '@/stores/redux/store';
import { RecoilRoot } from 'recoil';
import ThemeProvider from './utils/ThemeContext';

function App() {
  return (
    <ThemeProvider>
      <RecoilRoot>
        <Provider store={store}>
          <ErrorBoundary FallbackComponent={Error} onError={ErrorHandler}>
            <AmplifyAuthProvider>
              <ChatProvider>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </ChatProvider>
            </AmplifyAuthProvider>
          </ErrorBoundary>
        </Provider>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
