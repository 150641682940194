import { ChatRoom, OnCreateChatRoomSubscription } from '@/API';
import { cloneObject } from '@/utils/object_clone';
import { keys } from 'ts-transformer-keys';

export type IChatRoom = Omit<
  ChatRoom,
  'updatedAt' | 'owner' | '_version' | '_deleted' | '_lastChangedAt' | '__typename' | 'user' | 'messages' | 'agent'
> & {
  // Used for hiding chat room from user while it is being deleted
  hidden?: boolean;
};

export const cloneChatRoom = (chatRoom: ChatRoom): IChatRoom => {
  return cloneObject<ChatRoom, IChatRoom>(chatRoom, keys<ChatRoom>(), keys<IChatRoom>());
};

type SubscribeChatRoom = NonNullable<OnCreateChatRoomSubscription['onCreateChatRoom']>;
export const cloneSubscribeChatRoom = (chatRoom: SubscribeChatRoom): IChatRoom => {
  return cloneObject<SubscribeChatRoom, IChatRoom>(chatRoom, keys<SubscribeChatRoom>(), keys<IChatRoom>());
};
