/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTokenKey = /* GraphQL */ `
  subscription OnCreateTokenKey($filter: ModelSubscriptionTokenKeyFilterInput) {
    onCreateTokenKey(filter: $filter) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
      __typename
    }
  }
`;
export const onUpdateTokenKey = /* GraphQL */ `
  subscription OnUpdateTokenKey($filter: ModelSubscriptionTokenKeyFilterInput) {
    onUpdateTokenKey(filter: $filter) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
      __typename
    }
  }
`;
export const onDeleteTokenKey = /* GraphQL */ `
  subscription OnDeleteTokenKey($filter: ModelSubscriptionTokenKeyFilterInput) {
    onDeleteTokenKey(filter: $filter) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
      __typename
    }
  }
`;
export const onCreateChatRoom = /* GraphQL */ `
  subscription OnCreateChatRoom(
    $filter: ModelSubscriptionChatRoomFilterInput
    $userID: String
  ) {
    onCreateChatRoom(filter: $filter, userID: $userID) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      messages {
        nextToken
        startedAt
        __typename
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMessageTimestamp
      lastMessageText
      totalQuestionAmount
      usedQuestionAmount
      expirationDate
      usingPeriod
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateChatRoom = /* GraphQL */ `
  subscription OnUpdateChatRoom(
    $filter: ModelSubscriptionChatRoomFilterInput
    $userID: String
  ) {
    onUpdateChatRoom(filter: $filter, userID: $userID) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      messages {
        nextToken
        startedAt
        __typename
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMessageTimestamp
      lastMessageText
      totalQuestionAmount
      usedQuestionAmount
      expirationDate
      usingPeriod
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteChatRoom = /* GraphQL */ `
  subscription OnDeleteChatRoom(
    $filter: ModelSubscriptionChatRoomFilterInput
    $userID: String
  ) {
    onDeleteChatRoom(filter: $filter, userID: $userID) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      messages {
        nextToken
        startedAt
        __typename
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMessageTimestamp
      lastMessageText
      totalQuestionAmount
      usedQuestionAmount
      expirationDate
      usingPeriod
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUserToken = /* GraphQL */ `
  subscription OnCreateUserToken(
    $filter: ModelSubscriptionUserTokenFilterInput
    $owner: String
  ) {
    onCreateUserToken(filter: $filter, owner: $owner) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userTokenUserId
      userTokenHistories {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserToken = /* GraphQL */ `
  subscription OnUpdateUserToken(
    $filter: ModelSubscriptionUserTokenFilterInput
    $owner: String
  ) {
    onUpdateUserToken(filter: $filter, owner: $owner) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userTokenUserId
      userTokenHistories {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserToken = /* GraphQL */ `
  subscription OnDeleteUserToken(
    $filter: ModelSubscriptionUserTokenFilterInput
    $owner: String
  ) {
    onDeleteUserToken(filter: $filter, owner: $owner) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userTokenUserId
      userTokenHistories {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserTokenHistory = /* GraphQL */ `
  subscription OnCreateUserTokenHistory(
    $filter: ModelSubscriptionUserTokenHistoryFilterInput
    $owner: String
  ) {
    onCreateUserTokenHistory(filter: $filter, owner: $owner) {
      id
      amount
      date
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userTokenID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserTokenHistory = /* GraphQL */ `
  subscription OnUpdateUserTokenHistory(
    $filter: ModelSubscriptionUserTokenHistoryFilterInput
    $owner: String
  ) {
    onUpdateUserTokenHistory(filter: $filter, owner: $owner) {
      id
      amount
      date
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userTokenID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserTokenHistory = /* GraphQL */ `
  subscription OnDeleteUserTokenHistory(
    $filter: ModelSubscriptionUserTokenHistoryFilterInput
    $owner: String
  ) {
    onDeleteUserTokenHistory(filter: $filter, owner: $owner) {
      id
      amount
      date
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userTokenID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateGiftCard = /* GraphQL */ `
  subscription OnCreateGiftCard($filter: ModelSubscriptionGiftCardFilterInput) {
    onCreateGiftCard(filter: $filter) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
      __typename
    }
  }
`;
export const onUpdateGiftCard = /* GraphQL */ `
  subscription OnUpdateGiftCard($filter: ModelSubscriptionGiftCardFilterInput) {
    onUpdateGiftCard(filter: $filter) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
      __typename
    }
  }
`;
export const onDeleteGiftCard = /* GraphQL */ `
  subscription OnDeleteGiftCard($filter: ModelSubscriptionGiftCardFilterInput) {
    onDeleteGiftCard(filter: $filter) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
      __typename
    }
  }
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment(
    $filter: ModelSubscriptionPaymentFilterInput
    $userID: String
  ) {
    onCreatePayment(filter: $filter, userID: $userID) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment(
    $filter: ModelSubscriptionPaymentFilterInput
    $userID: String
  ) {
    onUpdatePayment(filter: $filter, userID: $userID) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment(
    $filter: ModelSubscriptionPaymentFilterInput
    $userID: String
  ) {
    onDeletePayment(filter: $filter, userID: $userID) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $ownerUserID: String
  ) {
    onCreateMessage(filter: $filter, ownerUserID: $ownerUserID) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        totalQuestionAmount
        usedQuestionAmount
        expirationDate
        usingPeriod
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $ownerUserID: String
  ) {
    onUpdateMessage(filter: $filter, ownerUserID: $ownerUserID) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        totalQuestionAmount
        usedQuestionAmount
        expirationDate
        usingPeriod
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $ownerUserID: String
  ) {
    onDeleteMessage(filter: $filter, ownerUserID: $ownerUserID) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        totalQuestionAmount
        usedQuestionAmount
        expirationDate
        usingPeriod
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
        __typename
      }
      chatRooms {
        nextToken
        startedAt
        __typename
      }
      giftCards {
        nextToken
        startedAt
        __typename
      }
      payments {
        nextToken
        startedAt
        __typename
      }
      tokenKeys {
        nextToken
        startedAt
        __typename
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userUserTokenId
      userTokenHistories {
        nextToken
        startedAt
        __typename
      }
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userPartnerAccountId
      userPartnerAccount {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userPartnerId
      userPartner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      apiKeys {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
        __typename
      }
      chatRooms {
        nextToken
        startedAt
        __typename
      }
      giftCards {
        nextToken
        startedAt
        __typename
      }
      payments {
        nextToken
        startedAt
        __typename
      }
      tokenKeys {
        nextToken
        startedAt
        __typename
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userUserTokenId
      userTokenHistories {
        nextToken
        startedAt
        __typename
      }
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userPartnerAccountId
      userPartnerAccount {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userPartnerId
      userPartner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      apiKeys {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
        __typename
      }
      chatRooms {
        nextToken
        startedAt
        __typename
      }
      giftCards {
        nextToken
        startedAt
        __typename
      }
      payments {
        nextToken
        startedAt
        __typename
      }
      tokenKeys {
        nextToken
        startedAt
        __typename
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userUserTokenId
      userTokenHistories {
        nextToken
        startedAt
        __typename
      }
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userPartnerAccountId
      userPartnerAccount {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userPartnerId
      userPartner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      apiKeys {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreatePartnerAccount = /* GraphQL */ `
  subscription OnCreatePartnerAccount(
    $filter: ModelSubscriptionPartnerAccountFilterInput
  ) {
    onCreatePartnerAccount(filter: $filter) {
      id
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      partnerAccountUserId
      partnerAccountUser {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      agents {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePartnerAccount = /* GraphQL */ `
  subscription OnUpdatePartnerAccount(
    $filter: ModelSubscriptionPartnerAccountFilterInput
  ) {
    onUpdatePartnerAccount(filter: $filter) {
      id
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      partnerAccountUserId
      partnerAccountUser {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      agents {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePartnerAccount = /* GraphQL */ `
  subscription OnDeletePartnerAccount(
    $filter: ModelSubscriptionPartnerAccountFilterInput
  ) {
    onDeletePartnerAccount(filter: $filter) {
      id
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      partnerAccountUserId
      partnerAccountUser {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      agents {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAgent = /* GraphQL */ `
  subscription OnCreateAgent($filter: ModelSubscriptionAgentFilterInput) {
    onCreateAgent(filter: $filter) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
        __typename
      }
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      partnerAccounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAgent = /* GraphQL */ `
  subscription OnUpdateAgent($filter: ModelSubscriptionAgentFilterInput) {
    onUpdateAgent(filter: $filter) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
        __typename
      }
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      partnerAccounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAgent = /* GraphQL */ `
  subscription OnDeleteAgent($filter: ModelSubscriptionAgentFilterInput) {
    onDeleteAgent(filter: $filter) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
        __typename
      }
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      partnerAccounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePartner = /* GraphQL */ `
  subscription OnCreatePartner(
    $filter: ModelSubscriptionPartnerFilterInput
    $partnerUserId: String
  ) {
    onCreatePartner(filter: $filter, partnerUserId: $partnerUserId) {
      id
      name
      address
      website
      logo
      description
      status
      apiKeys {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      partnerUserId
      partnerUser {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      agents {
        nextToken
        startedAt
        __typename
      }
      partnerAccounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePartner = /* GraphQL */ `
  subscription OnUpdatePartner(
    $filter: ModelSubscriptionPartnerFilterInput
    $partnerUserId: String
  ) {
    onUpdatePartner(filter: $filter, partnerUserId: $partnerUserId) {
      id
      name
      address
      website
      logo
      description
      status
      apiKeys {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      partnerUserId
      partnerUser {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      agents {
        nextToken
        startedAt
        __typename
      }
      partnerAccounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePartner = /* GraphQL */ `
  subscription OnDeletePartner(
    $filter: ModelSubscriptionPartnerFilterInput
    $partnerUserId: String
  ) {
    onDeletePartner(filter: $filter, partnerUserId: $partnerUserId) {
      id
      name
      address
      website
      logo
      description
      status
      apiKeys {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      partnerUserId
      partnerUser {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      agents {
        nextToken
        startedAt
        __typename
      }
      partnerAccounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateApiKey = /* GraphQL */ `
  subscription OnCreateApiKey(
    $filter: ModelSubscriptionApiKeyFilterInput
    $userID: String
  ) {
    onCreateApiKey(filter: $filter, userID: $userID) {
      id
      name
      key
      status
      requestsPerMonth
      requestsPerHour
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateApiKey = /* GraphQL */ `
  subscription OnUpdateApiKey(
    $filter: ModelSubscriptionApiKeyFilterInput
    $userID: String
  ) {
    onUpdateApiKey(filter: $filter, userID: $userID) {
      id
      name
      key
      status
      requestsPerMonth
      requestsPerHour
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteApiKey = /* GraphQL */ `
  subscription OnDeleteApiKey(
    $filter: ModelSubscriptionApiKeyFilterInput
    $userID: String
  ) {
    onDeleteApiKey(filter: $filter, userID: $userID) {
      id
      name
      key
      status
      requestsPerMonth
      requestsPerHour
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAgentPartnerAccounts = /* GraphQL */ `
  subscription OnCreateAgentPartnerAccounts(
    $filter: ModelSubscriptionAgentPartnerAccountsFilterInput
  ) {
    onCreateAgentPartnerAccounts(filter: $filter) {
      id
      partnerAccountID
      agentID
      partnerAccount {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      agent {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAgentPartnerAccounts = /* GraphQL */ `
  subscription OnUpdateAgentPartnerAccounts(
    $filter: ModelSubscriptionAgentPartnerAccountsFilterInput
  ) {
    onUpdateAgentPartnerAccounts(filter: $filter) {
      id
      partnerAccountID
      agentID
      partnerAccount {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      agent {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAgentPartnerAccounts = /* GraphQL */ `
  subscription OnDeleteAgentPartnerAccounts(
    $filter: ModelSubscriptionAgentPartnerAccountsFilterInput
  ) {
    onDeleteAgentPartnerAccounts(filter: $filter) {
      id
      partnerAccountID
      agentID
      partnerAccount {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      agent {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
