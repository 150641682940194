import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { DOTS, usePagination } from '@/components/Pagination/usePagination';

const PaginationItem = ({ page, currentPage, onPageChange }: any) => {
  const liClasses = classNames({
    'page-item': true,
    active: page === currentPage
  });
  return (
    <li className={liClasses} onClick={() => onPageChange(page)}>
      <a className='page-link'>{page}</a>
    </li>
  );
};
const Pagination = (props: any) => {
  const { currentPage, total, limit, onPageChange, siblingCount = 1 } = props;

  const paginationRange = usePagination({
    currentPage,
    total,
    siblingCount,
    limit
  });

  if (!paginationRange) {
    return null;
  }

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  function toNextPage() {
    onPageChange((page: number) => page + 1);
  }

  function toPreviousPage() {
    onPageChange((page: number) => page - 1);
  }

  return (
    <>
      <nav className='w-full sm:mr-auto sm:w-auto'>
        <ul className=' pagination'>
          <div className='intro-y col-span-12 flex items-center space-x-5 sm:flex-row sm:flex-nowrap'>
            <li className='page-item'>
              <button onClick={toPreviousPage} className='page-link'>
                <a className='sr-only'>Previous Page</a>
                <ChevronLeftIcon className='h-4 w-4' aria-hidden='true' />
              </button>
            </li>
            {paginationRange.map((page, index) => {
              if (page === DOTS) {
                return (
                  <li key={index} className='page-item'>
                    <a className='page-link'>...</a>
                  </li>
                );
              } else {
                return <PaginationItem key={index} page={page} currentPage={currentPage} onPageChange={onPageChange} />;
              }
            })}
            <li className='page-item'>
              <button onClick={toNextPage} className='page-link'>
                <span className='sr-only'>Next Page</span>
                <ChevronRightIcon className='h-4 w-4' aria-hidden='true' />
              </button>
            </li>
          </div>
        </ul>
      </nav>
    </>
  );
};
export default Pagination;
