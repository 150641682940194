import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { IUser } from '@/types/IUser';
import { RootState } from '@/stores/redux/store';

export const updateUserTotalTokenAmount = createAsyncThunk<
  void,
  {
    userID: string;
    totalTokenAmount: number;
  }
>('users/updateUserTotalTokenAmount', async ({ userID, totalTokenAmount }, { dispatch, getState }) => {
  const rootState = getState() as RootState;
  const user = selectUserById(rootState, userID);
  if (user && user.userToken) {
    dispatch(
      usersActions.updateUser({
        id: userID,
        changes: {
          userToken: {
            ...user.userToken,
            totalTokenAmount
          }
        }
      })
    );
  } else {
    console.error('User not found');
  }
});

export const usersAdapter = createEntityAdapter<IUser>({
  selectId: (user) => user.id
});

const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(),
  reducers: {
    addUser(state, action: PayloadAction<IUser>) {
      usersAdapter.addOne(state, action.payload);
    },
    addUsers(state, action: PayloadAction<IUser[]>) {
      usersAdapter.addMany(state, action.payload);
    },
    removeUser(state, action: PayloadAction<string>) {
      usersAdapter.removeOne(state, action.payload);
    },
    resetUsers() {
      return usersAdapter.getInitialState();
    },
    updateUser(state, action: PayloadAction<Update<IUser>>) {
      usersAdapter.updateOne(state, action.payload);
    }
  }
});

// Get all users selector
export const { selectAll: selectAllUsers, selectById: selectUserById } = usersAdapter.getSelectors((state: RootState) => state.users);

export const usersActions = usersSlice.actions;
export default usersSlice;
