import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { IAuthUser } from '@/types/IAuthUser';
import { useInterval } from 'usehooks-ts';

interface IAuthContext {
  isSignedIn: boolean;
  isDeviceRemembered: boolean;
  deviceKey: string;
  user: IAuthUser | null;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  successMessage: string;
  setSuccessMessage: (message: string) => void;
  signIn: (email: string, password: string, remember: boolean) => Promise<IAuthUser | null>;
  signUp: (email: string, password: string, firstName: string, lastName: string, phoneNumber: string) => Promise<void>;
  signOut: () => void;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  codeVerify: (code: string, email: string) => Promise<void>;
  updateDeviceKey: (key: string) => void;
  isAdmin: () => Promise<boolean>;
}

const AuthContext = createContext<IAuthContext>({
  isSignedIn: false,
  isDeviceRemembered: false,
  deviceKey: '',
  user: null,
  errorMessage: '',
  setErrorMessage: () => {
    console.warn('setErrorMessage not implemented');
  },
  successMessage: '',
  setSuccessMessage: () => {
    console.warn('setSuccessMessage not implemented');
  },
  signIn: () => {
    console.warn('signIn not implemented');
    return Promise.resolve(null);
  },
  signUp: () => {
    console.warn('signUp not implemented');
    return Promise.resolve();
  },
  signOut: () => {
    console.warn('signOut not implemented');
  },
  changePassword: () => {
    console.warn('changePassword not implemented');
    return Promise.resolve();
  },
  codeVerify: () => {
    console.warn('codeVerify not implemented');
    return Promise.resolve();
  },
  updateDeviceKey: () => {
    console.warn('updateDeviceKey not implemented');
  },
  isAdmin: () => {
    console.warn('isAdmin not implemented');
    return Promise.resolve(false);
  }
});

interface IAmplifyAuthProviderProps {
  children: React.ReactNode;
}

// Wrap your app with <AmplifyAuthProvider />
export const AmplifyAuthProvider = ({ children }: IAmplifyAuthProviderProps) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// Access auth values and functions with custom useAuth hook
export const useAuth = () => useContext(AuthContext);

const useProvideAuth = () => {
  const [user, setUser] = useState<IAuthUser | null>(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isDeviceRemembered, setIsDeviceRemembered] = useState(false);
  const [deviceKey, setDeviceKey] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const updateUsersAfterAuth = useCallback((session: CognitoUserSession, successLogMessage: string) => {
    // Define your user schema per your needs
    const newUser: IAuthUser = {
      id: session.getIdToken().payload.sub,
      email: session.getIdToken().payload.email,
      firstName: session.getIdToken().payload.name,
      lastName: session.getIdToken().payload.family_name,
      phoneNumber: session.getIdToken().payload.phone_number,
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken()
    };

    if (successLogMessage) {
      console.log(successLogMessage);
    }

    setIsSignedIn(true);
    setUser(newUser);
    setErrorMessage('');
    setSuccessMessage('');
  }, []);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        updateUsersAfterAuth(session, 'Session updated successfully');
      })
      .catch(() => {
        // Maybe the refresh token expired, so we need to sign out
        signOut().then(() => {
          console.log('Session expired, signed out successfully');
        });
      });
  }, []);

  useInterval(async () => {
    // Refresh the user's session
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (cognitoUser) {
        const currentSession = cognitoUser.getSignInUserSession();
        cognitoUser.refreshSession(currentSession.refreshToken, (err: any, session: CognitoUserSession) => {
          if (err) {
            console.log('Error refreshing session: ', err);
            return;
          }

          if (!session) {
            console.log('No session returned');
            return;
          }

          // Define your user schema per your needs
          updateUsersAfterAuth(session, 'Session refreshed successfully');
        });
      }
    } catch (err) {
      console.log('Error refreshing session', err);

      // Maybe the refresh token expired, so we need to sign out
      await signOut();
    }
  }, 30 * 60 * 1000);

  const signIn = async (email: string, password: string, rememberDevice: boolean) => {
    try {
      const cognitoUser = await Auth.signIn(email, password);

      // Set user data and access token to memory
      const {
        attributes,
        signInUserSession: { accessToken, idToken }
      } = cognitoUser;

      const newUser: IAuthUser = {
        id: attributes.sub,
        email: attributes.email,
        firstName: attributes.name,
        lastName: attributes.family_name,
        phoneNumber: attributes.phone_number,
        accessToken: accessToken.jwtToken,
        idToken: idToken.jwtToken
      };

      setIsSignedIn(true);
      setUser(newUser);
      setIsDeviceRemembered(rememberDevice);

      console.log('Logged in successfully');
      setErrorMessage('');
      setSuccessMessage('Đăng nhập thành công');

      return newUser;
    } catch (err: any) {
      switch (err.code) {
        case 'UserNotConfirmedException':
          setErrorMessage('Vui lòng xác thực email trước khi đăng nhập');
          setSuccessMessage('');
          break;
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
          setErrorMessage('Email hoặc mật khẩu không đúng');
          setSuccessMessage('');
          break;
        default:
          setErrorMessage('Có lỗi xảy ra, vui lòng thử lại');
          setSuccessMessage('');
          break;
      }

      return null;
    }
  };

  const signUp = async (email: string, password: string, firstName: string, lastName: string, phoneNumber: string) => {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          name: firstName,
          family_name: lastName,
          phone_number: phoneNumber,
          email
        }
      });

      const newUser = {
        id: '',
        email,
        firstName,
        lastName,
        phoneNumber,
        accessToken: '',
        idToken: ''
      };

      // Need to wait for confirmation
      setIsSignedIn(false);
      setUser(newUser);

      setSuccessMessage('Đăng ký thành công, vui lòng xác thực email');
      setErrorMessage('');
    } catch (err: any) {
      switch (err.code) {
        case 'UsernameExistsException':
          setErrorMessage('Email đã được sử dụng, vui lòng sử dụng email khác');
          setSuccessMessage('');
          break;
        default:
          setErrorMessage('Đăng ký không thành công, vui lòng thử lại');
          setSuccessMessage('');
          break;
      }

      // Throw error to parent component
      throw err;
    }
  };

  const signOut = async () => {
    // Sign out of Amplify
    await Auth.signOut();

    // Clear auth state
    setIsSignedIn(false);
    setUser(null);
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, newPassword);
      setSuccessMessage('Đổi mật khẩu thành công');
      setErrorMessage('');
    } catch (err: any) {
      switch (err.code) {
        case 'NotAuthorizedException':
          setErrorMessage('Mật khẩu cũ không đúng');
          setSuccessMessage('');
          break;
        default:
          setErrorMessage('Đổi mật khẩu không thành công, vui lòng thử lại');
          setSuccessMessage('');
          break;
      }

      // Throw error to parent component
      throw err;
    }
  };

  const codeVerify = async (code: string, email: string) => {
    try {
      console.log('Verifying code, user email: ', email, 'code: ', code);
      await Auth.confirmSignUp(email, code);
      setSuccessMessage('Tài khoản đã được xác thực, vui lòng đăng nhập');
      setErrorMessage('');
    } catch (err) {
      console.error('Error confirming sign up', err);
      setErrorMessage('Lỗi xác thực tài khoản, vui lòng thử lại');
      setSuccessMessage('');

      // Throw error to parent component
      throw err;
    }
  };

  const updateDeviceKey = (key: string) => {
    console.log('Updating device ID: ', key);
    setDeviceKey(key);
  };

  const isAdmin = async () => {
    if (!isSignedIn) {
      return false;
    }

    const currentUser = await Auth.currentAuthenticatedUser();
    const groups = currentUser.signInUserSession.accessToken.payload['cognito:groups'];
    return groups && groups.includes('Admin');
  };

  return {
    user,
    isSignedIn,
    isDeviceRemembered,
    deviceKey,
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
    signIn,
    signUp,
    signOut,
    changePassword,
    codeVerify,
    updateDeviceKey,
    isAdmin
  };
};
