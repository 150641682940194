import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Transition from '../components/utils/Transition';

import userImg from '../assets/images/avatar-1.jpg';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { useAppDispatch } from '@/stores/redux/hooks';
import { usersActions } from '@/stores/redux/users/users-slice';
import { userTokenActions } from '@/stores/redux/user-token/user-token-slice';
import { chatRoomsActions } from '@/stores/redux/chat-rooms/chat-rooms-slice';
import { chatRoomActions } from '@/stores/redux/chat-room/chat-room-slice';

const DropdownProfile = ({ align = 'left' }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isSignedIn, user } = useAuth();

  const trigger = useRef<HTMLDivElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { signOut } = useAuth();

  const handleLogout = async () => {
    try {
      await signOut();

      // Reset users state
      dispatch(usersActions.resetUsers());

      // Reset user token
      dispatch(userTokenActions.resetUserToken());

      // Reset chat rooms state
      dispatch(chatRoomsActions.resetChatRooms());

      // Reset chat room state
      dispatch(chatRoomActions.resetChatRoom());

      await navigate('/oauth/signin', { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className='relative inline-flex' ref={wrapperRef}>
      <button
        className='group inline-flex w-fit items-center justify-center'
        aria-haspopup='true'
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}>
        <img className='h-8 w-8 rounded-full' src={userImg} width='32' height='32' alt='User' />
        <div className='flex items-center truncate'>
          <span className='ml-2 hidden truncate text-sm font-medium group-hover:text-slate-800 dark:text-slate-300 dark:group-hover:text-slate-200 md:block'>
            {user?.lastName ? user?.lastName : '' + ' ' + user?.firstName}
          </span>
          <svg className='ml-1 hidden h-3 w-3 shrink-0 fill-current text-slate-400 md:block' viewBox='0 0 12 12'>
            <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
          </svg>
        </div>
      </button>

      <Transition
        className={`absolute top-full z-10 mt-1 w-36 origin-top-right overflow-hidden rounded border border-slate-200 bg-white py-1.5 shadow-lg dark:border-slate-700 dark:bg-slate-800 ${
          align === 'right' ? 'right-0' : 'left-0'
        }`}
        show={dropdownOpen}
        appear={dropdownOpen}
        enter='transition ease-out duration-200 transform'
        enterStart='opacity-0 -translate-y-2'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-out duration-200'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'>
        <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
          <div className='mb-1 border-b border-slate-200 px-3 pb-2 pt-0.5 dark:border-slate-700'>
            <div className='w-full font-medium text-slate-800 dark:text-slate-100'>
              {user?.lastName ? user?.lastName : '' + ' ' + user?.firstName}
            </div>
            <div className='text-xs italic text-slate-500 dark:text-slate-400'>Khách</div>
          </div>
          <ul>
            <li>
              <Link
                className='flex w-full items-center px-3 py-1 text-sm font-medium text-sky-500 hover:text-sky-600 dark:hover:text-sky-400'
                to='/oauth/signin'
                onClick={handleLogout}>
                Đăng xuất
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
};

export default DropdownProfile;
