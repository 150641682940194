import { Agent } from '@/API';
import { keys } from 'ts-transformer-keys';
import { cloneObject } from '@/utils/object_clone';

// IUser type is extended from User type, omitting __typename, createdAt, updatedAt, _version, _deleted, _lastChangedAt and owner fields
export type IAgent = Omit<Agent, 'updatedAt' | 'createdAt' | 'owner' | '_version' | '_deleted' | '_lastChangedAt' | '__typename' | 'chatRooms'>;

export const cloneAgent = (agent: Agent): IAgent => {
  return cloneObject<Agent, IAgent>(agent, keys<Agent>(), keys<IAgent>());
};
