import { useEffect } from 'react';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { configureLocalStorage } from '@/configureAmplify';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

interface SignInFormProps {
  onShowDialogChange: (showDialog: boolean) => void;
}

const SignInForm = ({ onShowDialogChange }: SignInFormProps) => {
  const { isSignedIn, signIn, setSuccessMessage, setErrorMessage } = useAuth();

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.classList.remove('main', 'error-page');
      body.classList.add('login');
    }
  }, []);

  const initialValues = {
    email: '',
    password: '',
    remember: true
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email không hợp lệ').required('Email không được để trống'),
    password: Yup.string().required('Mật khẩu không được để trống').min(8, 'Mật khẩu phải có ít nhất 8 ký tự'),
    remember: Yup.boolean()
  });

  const handleSubmit = async (values: any) => {
    const { email, password, remember } = values;
    console.log('values: ', values);

    if (!email || !password) {
      return;
    }

    // Configure local storage
    console.log('Configuring for remembering user');
    configureLocalStorage();

    const user = await signIn(email, password, remember);
    if (user) {
      console.log('Signed in');
      console.log(user);
      onShowDialogChange(false); // Close the modal after successful sign in
    } else {
      console.error('Sign in failed');
    }
  };

  // Reset errorMessage and successMessage on unmount
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  const handleSignInSocial = async (provider: string) => {
    try {
      if (provider === 'facebook') {
        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
      } else if (provider === 'google') {
        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
        console.log('Signed in with Google');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // @ts-ignore
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ values, setFieldValue }) => (
        <div className='mt-7 rounded-xl border border-gray-200 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800'>
          <div className='p-4 sm:p-7'>
            <div className='text-center'>
              <h1 className='block text-2xl font-bold text-gray-800 dark:text-white'>Đăng nhập</h1>
              <p className='mt-2 text-sm text-gray-600 dark:text-gray-400'>
                Chưa có tài khoản?{' '}
                <Link className='font-medium text-blue-600 decoration-2 hover:font-bold' to='/oauth/signup'>
                  Đăng ký ngay để trải nghiệm HUTECH Chatbot
                </Link>
              </p>
            </div>

            <div className='mt-5'>
              {/*<button*/}
              {/*  type='button'*/}
              {/*  className='inline-flex w-full items-center justify-center gap-2 rounded-md border bg-white px-4 py-3 align-middle text-sm font-medium text-gray-700 shadow-sm transition-all hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-slate-800 dark:hover:text-white dark:focus:ring-offset-gray-800'*/}
              {/*  onClick={() => handleSignInSocial('google')}>*/}
              {/*  <svg className='h-auto w-4' width='46' height='47' viewBox='0 0 46 47' fill='none'>*/}
              {/*    <path*/}
              {/*      d='M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z'*/}
              {/*      fill='#4285F4'*/}
              {/*    />*/}
              {/*    <path*/}
              {/*      d='M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z'*/}
              {/*      fill='#34A853'*/}
              {/*    />*/}
              {/*    <path*/}
              {/*      d='M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z'*/}
              {/*      fill='#FBBC05'*/}
              {/*    />*/}
              {/*    <path*/}
              {/*      d='M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z'*/}
              {/*      fill='#EB4335'*/}
              {/*    />*/}
              {/*  </svg>*/}
              {/*  Đăng nhập với Google*/}
              {/*</button>*/}

              {/*<div className='flex items-center py-3 text-xs uppercase text-gray-400 before:mr-6 before:flex-[1_1_0%] before:border-t before:border-gray-200 after:ml-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 dark:text-gray-500 dark:before:border-gray-600 dark:after:border-gray-600'>*/}
              {/*  hoặc*/}
              {/*</div>*/}

              <Form>
                <div className='grid gap-y-4'>
                  <div>
                    <label htmlFor='email' className='mb-2 block text-sm dark:text-white'>
                      Địa chỉ email
                    </label>
                    <div className='relative'>
                      <Field
                        type='email'
                        id='email'
                        name='email'
                        className='block w-full rounded-md border-gray-200 px-4 py-3 text-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400'
                        required
                        aria-describedby='email-error'
                        placeholder='Nhập địa chỉ email'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('email', e.target.value);
                        }}
                      />
                      <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                        <svg className='h-5 w-5 text-red-500' width='16' height='16' fill='currentColor' viewBox='0 0 16 16' aria-hidden='true'>
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z' />
                        </svg>
                      </div>
                    </div>
                    <p className='mt-2 hidden text-xs text-red-600' id='email-error'>
                      Vui lòng nhập địa chỉ email hợp lệ để chúng tôi có thể liên lạc với bạn.
                    </p>
                  </div>

                  <div>
                    <div className='flex items-center justify-between'>
                      <label htmlFor='password' className='mb-2 block text-sm dark:text-white'>
                        Mật khẩu
                      </label>
                      <a className='text-sm font-medium text-blue-600 decoration-2 hover:underline' href='../examples/html/recover-account.html'>
                        Quên mật khẩu?
                      </a>
                    </div>
                    <div className='relative'>
                      <Field
                        type='password'
                        id='password'
                        name='password'
                        className='block w-full rounded-md border-gray-200 px-4 py-3 text-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400'
                        required
                        aria-describedby='password-error'
                        minLength='8'
                        maxLength='32'
                        autoComplete='current-password'
                        placeholder='Nhập mật khẩu'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('password', e.target.value);
                        }}
                      />
                      <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                        <svg className='h-5 w-5 text-red-500' width='16' height='16' fill='currentColor' viewBox='0 0 16 16' aria-hidden='true'>
                          <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z' />
                        </svg>
                      </div>
                    </div>
                    <p className='mt-2 hidden text-xs text-red-600' id='password-error'>
                      Mật khẩu phải có ít nhất 8 ký tự.
                    </p>
                  </div>

                  <div className='flex items-center'>
                    <div className='flex'>
                      <Field
                        id='remember-me'
                        name='remember-me'
                        type='checkbox'
                        className='pointer-events-none mt-0.5 shrink-0 rounded border-gray-200 text-blue-600 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-800 dark:checked:border-blue-500 dark:checked:bg-blue-500 dark:focus:ring-offset-gray-800'
                        onClick={() => {
                          setFieldValue('remember', !values['remember']);
                        }}
                      />
                    </div>
                    <div className='ml-3'>
                      <label htmlFor='remember-me' className='text-sm dark:text-white'>
                        Nhớ mật khẩu
                      </label>
                    </div>
                  </div>

                  <button
                    type='submit'
                    className='inline-flex items-center justify-center gap-2 rounded-md border border-transparent bg-blue-500 px-4 py-3 text-sm font-semibold text-white transition-all hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800'>
                    Đăng nhập
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default SignInForm;
