import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import logo from '../assets/images/logo_hutech.png';
import AuthImage from '../assets/images/hutech-bg.jpg';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { Spinner } from 'react-bootstrap';
import { Amplify } from 'aws-amplify';
import awsConfig from '../aws-exports';
import Lucide from '@/base-components/Lucide';
import Notification from '@/base-components/Notification';

Amplify.configure(awsConfig);
const CodeVerificationPage = () => {
  const navigate = useNavigate();
  const [notification, setNotification] = useState<NotificationType | null>(null);
  const { isSignedIn, errorMessage, successMessage, setErrorMessage, setSuccessMessage, codeVerify } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();

  // Success notification
  const codeVerificationNotification = useRef<ReactElement>();
  const successNotificationToggle = () => {
    setNotification({
      header: 'Xác thực thành công',
      message: 'Đang chuyển hướng...',
      type: 'success'
    });
  };

  // Get current user email and password from location
  useEffect(() => {
    if (!location || !location.state) {
      return;
    }

    // @ts-ignore
    setEmail(location.state.email);
    // @ts-ignore
    setPassword(location.state.password);
  }, [location]);

  useEffect(() => {
    if (notification) {
      if (codeVerificationNotification && codeVerificationNotification.current) {
        // Show notification
        // @ts-ignore
        codeVerificationNotification.current.showToast();
      }
    }
  }, [notification]);

  // Navigate to dashboard if already signed in
  useEffect(() => {
    if (isSignedIn) {
      navigate('/', { replace: true });
    }
  }, [isSignedIn, navigate]);

  // Reset errorMessage and successMessage on unmount
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  const initialValues = {
    verificationCode: ''
  };

  const validationSchema = Yup.object().shape({
    verificationCode: Yup.string()
      .required('Verification code is required')
      .matches(/^\d{6}$/, 'Invalid verification code')
  });

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onSubmit = async (values: any) => {
    const { verificationCode } = values;

    if (!verificationCode) {
      return;
    }

    try {
      await codeVerify(verificationCode, email);

      // Success notification
      successNotificationToggle();

      // Set redirect to true to redirect to dashboard in 2 seconds
      await sleep(2000);
      navigate('/', { replace: true });
    } catch (error) {
      console.log('Code verification error', error);
    }
  };

  return (
    <main className='bg-white dark:bg-slate-900'>
      <Notification
        getRef={(el) => {
          if (el) {
            codeVerificationNotification.current = el;
          }
        }}
        options={{
          duration: 2000,
          callback: () => {
            setNotification(null);
          }
        }}
        className='flex'>
        <Lucide icon='CheckCircle' className={notification && notification.type === 'success' ? 'text-success' : 'text-danger'} />
        <div className='mx-4'>
          <div className='font-medium'>{notification && notification.header}</div>
          <div className='mt-1 text-slate-500'>{notification && notification.message}</div>
        </div>
      </Notification>
      <div className='relative md:flex'>
        {/* Content */}
        <div className='md:w-1/2'>
          <div className='flex h-full min-h-[100dvh] flex-col after:flex-1'>
            {/* Header */}
            <div className='flex-1'>
              <div className='flex h-16 items-center justify-between px-4 sm:px-6 lg:px-8'>
                {/* Logo */}
                <Link className='flex space-x-1' to='/'>
                  <img className='h-12 w-auto' src={logo} alt='logo' />
                  <p className='hidden text-4xl font-bold text-sky-600 md:block'>HUTECH</p>
                </Link>
              </div>
            </div>

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className='mx-auto w-full max-w-sm px-4 py-8'>
                  <h1 className='mb-6 text-xl font-bold text-sky-700'>
                    Xác thực tài khoản ngay
                    <br /> để trò chuyện với HUTECH Chatbot
                  </h1>
                  {successMessage && (
                    <div className='mt-4 flex items-center text-center text-green-500'>
                      <Lucide icon='CheckCircle' className='mr-2 text-green-500' />
                      <div className='text-sm'>{successMessage}</div>
                    </div>
                  )}
                  {errorMessage && (
                    <div className='mt-4 flex items-center text-center text-red-500'>
                      <Lucide icon='XCircle' className='mr-2 text-red-500' />
                      <div className='text-sm'>{errorMessage}</div>
                    </div>
                  )}
                  {/* Form */}
                  <div className='space-y-4'>
                    <div>
                      <label className='text-md mb-1 block font-medium' htmlFor='verification code'>
                        Mã xác minh <span className='text-rose-500'>*</span>
                      </label>
                      <Field
                        name='verificationCode'
                        className='form-input w-full -space-y-px rounded-md shadow-sm'
                        type='text'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('verificationCode', e.target.value);
                        }}
                      />
                      <ErrorMessage name='verificationCode'>
                        {(msg: string) => <span className='block px-2 py-1 text-red-600'>{msg}</span>}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className='mt-6 flex items-center justify-between'>
                    <button className='btn ml-3 whitespace-nowrap bg-blue-500 text-white hover:bg-blue-800' type='submit'>
                      {isSubmitting ? <Spinner className='animate-spin' /> : 'Xác thực ngay'}
                    </button>
                  </div>
                  {/* Footer */}
                  <div className='mt-6 border-t border-slate-200 pt-5 dark:border-slate-700'>
                    <div className='text-sm'>
                      Bạn chưa có tài khoản?{' '}
                      <Link className='font-medium text-blue-700 hover:font-bold hover:text-blue-700' to='/oauth/signup'>
                        Đăng ký ngay
                      </Link>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {/* Image */}
        <div className='absolute inset-y-0 right-0 hidden md:block md:w-1/2' aria-hidden='true'>
          <img className='h-full w-full object-cover object-center' src={AuthImage} width='760' height='1024' alt='Authentication' />
          <img
            className='absolute left-0 top-1/4 hidden -translate-x-1/2 lg:block'
            src={logo}
            width='150'
            height='160'
            alt='Authentication decoration'
          />
        </div>
      </div>
    </main>
  );
};

export default CodeVerificationPage;
