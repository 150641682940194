import { useMemo } from 'react';

export const DOTS = '...';

const range = ({ start, end }: any) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

type IProps = {
  currentPage: number;
  total: number;
  siblingCount: number;
  limit: number;
};
export const usePagination = ({ currentPage, total, siblingCount = 1, limit }: IProps) => {
  return useMemo(() => {
    const totalPageCount = Math.ceil(total / limit);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range({ start: 1, end: totalPageCount });
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range({ start: 1, end: leftItemCount });

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range({
        start: totalPageCount - rightItemCount + 1,
        end: totalPageCount
      });
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range({ start: leftSiblingIndex, end: rightSiblingIndex });
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [total, limit, siblingCount, currentPage]);
};
