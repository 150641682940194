import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import chat from './chat';
import userTokenSlice from '@/stores/redux/user-token/user-token-slice';
import agentsSlice from '@/stores/redux/agents/agents-slice';
import usersSlice from '@/stores/redux/users/users-slice';
import whisperConfigSlice from '@/stores/redux/whisper/whisper-config-slice';

const store = configureStore({
  reducer: {
    chat: chat,
    agents: agentsSlice.reducer,
    userToken: userTokenSlice.reducer,
    users: usersSlice.reducer,
    whisperConfig: whisperConfigSlice.reducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Simple Thunk type
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export default store;
