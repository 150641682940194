// GraphQL API to get all messages in a room
import { API, graphqlOperation } from 'aws-amplify';
import { chatRoomMessages, listMessages } from '@/graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import {
  ChatRoomMessagesQuery,
  ListMessagesQuery,
  OnCreateMessageSubscription,
  OnDeleteMessageSubscription,
  OnUpdateMessageSubscription
} from '@/API';
import { AppDispatch } from '@/stores/redux/store';
import { chatRoomActions } from '@/stores/redux/chat-room/chat-room-slice';
import { messageContentHash } from '@/utils/message_content_hasher';
import { cloneMessage } from '@/types/IMessage';
import { onCreateMessage, onDeleteMessage, onUpdateMessage } from '@/graphql/subscriptions';

export const gqlGetAllMessagesByRoom = async (chatRoomID: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get all messages by room ${retry} time(s)`);
    }

    try {
      const allMessages = [];
      let nextToken = null;
      do {
        const result = (await API.graphql(
          graphqlOperation(chatRoomMessages, {
            chatRoomID,
            limit: 1000,
            nextToken
          })
        )) as GraphQLResult<ChatRoomMessagesQuery>;
        if (result.data?.chatRoomMessages?.items) {
          allMessages.push(...result.data.chatRoomMessages.items);
          nextToken = result.data.chatRoomMessages.nextToken;
        }
      } while (nextToken);
      return allMessages.filter((message) => message && !message._deleted);
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get all messages by room');
  }

  return [];
};

export class MessageSubscription {
  _onCreateMessageSubscription: { unsubscribe: () => void } | null = null;
  _onUpdateMessageSubscription: { unsubscribe: () => void } | null = null;
  _onDeleteMessageSubscription: { unsubscribe: () => void } | null = null;

  constructor(private _dispatch: AppDispatch, private _chatRoomID: string, private _userID: string) {
    // Message subscriptions
  }

  // Subscribe to the onCreateMessage subscription
  private _subscribeToOnCreateMessageSubscription() {
    this._onCreateMessageSubscription = (
      API.graphql(
        graphqlOperation(onCreateMessage, {
          filter: {
            chatRoomID: {
              eq: this._chatRoomID
            }
          },
          ownerUserID: this._userID
        })
      ) as any
    ).subscribe({
      next: ({ value }: { value: GraphQLResult<OnCreateMessageSubscription> }) => {
        if (value.data?.onCreateMessage) {
          const message = cloneMessage(value.data.onCreateMessage);
          message.contentHash = messageContentHash(message);
          this._dispatch(chatRoomActions.addOrUpdateMessagesFromDatastore([message]));
        }
      },
      error: (error: any) => {
        console.log('onCreateMessage subscription error: ', error);
      }
    });
  }

  // Subscribe to the onUpdateMessage subscription
  private _subscribeToOnUpdateMessageSubscription() {
    this._onUpdateMessageSubscription = (
      API.graphql(
        graphqlOperation(onUpdateMessage, {
          filter: {
            chatRoomID: {
              eq: this._chatRoomID
            }
          },
          ownerUserID: this._userID
        })
      ) as any
    ).subscribe({
      next: ({ value }: { value: GraphQLResult<OnUpdateMessageSubscription> }) => {
        if (value.data?.onUpdateMessage) {
          const message = cloneMessage(value.data.onUpdateMessage);
          message.contentHash = messageContentHash(message);
          this._dispatch(chatRoomActions.addOrUpdateMessagesFromDatastore([message]));
        }
      },
      error: (error: any) => {
        console.log('onUpdateMessage subscription error: ', error);
      }
    });
  }

  // Subscribe to the onDeleteMessage subscription
  private _subscribeToOnDeleteMessageSubscription() {
    this._onDeleteMessageSubscription = (
      API.graphql(
        graphqlOperation(onDeleteMessage, {
          filter: {
            chatRoomID: {
              eq: this._chatRoomID
            }
          },
          ownerUserID: this._userID
        })
      ) as any
    ).subscribe({
      next: ({ value }: { value: GraphQLResult<OnDeleteMessageSubscription> }) => {
        if (value.data?.onDeleteMessage && value.data?.onDeleteMessage?.id) {
          this._dispatch(chatRoomActions.removeMessage(value.data?.onDeleteMessage.id));
        }
      },
      error: (error: any) => {
        console.log('onDeleteMessage subscription error: ', error);
      }
    });
  }

  // Subscribe to all subscriptions
  async subscribe() {
    this._subscribeToOnCreateMessageSubscription();
    this._subscribeToOnUpdateMessageSubscription();
    this._subscribeToOnDeleteMessageSubscription();

    // Get all messages in the chat room
    const messages = await gqlGetAllMessagesByRoom(this._chatRoomID);
    if (messages) {
      this._dispatch(
        chatRoomActions.addOrUpdateMessagesFromDatastore(
          messages.map((message) => {
            const serializableMessage = cloneMessage(message!);
            serializableMessage.contentHash = messageContentHash(serializableMessage);
            return serializableMessage;
          })
        )
      );

      // Set chat room to ready
      this._dispatch(chatRoomActions.setChatRoomReady(true));
    }

    // TODO (MinhLuan): check if the data integration, as there might be some delay between the subscription and the data fetching
  }

  // Unsubscribe to all subscriptions
  unsubscribe() {
    if (this._onCreateMessageSubscription) {
      this._onCreateMessageSubscription.unsubscribe();
    }
    if (this._onUpdateMessageSubscription) {
      this._onUpdateMessageSubscription.unsubscribe();
    }
    if (this._onDeleteMessageSubscription) {
      this._onDeleteMessageSubscription.unsubscribe();
    }
  }
}
