import { createElement } from 'react';
import * as lucideIcons from 'lucide-react';

interface LucideIconProps {
  icon: string;
  className?: string;
}

const Lucide = (props: LucideIconProps) => {
  try {
    const { icon, className, ...computedProps } = props;

    // @ts-ignore
    if (lucideIcons[props.icon] !== undefined) {
      // @ts-ignore
      return createElement(lucideIcons[props.icon], {
        ...computedProps,
        className: `lucide ${props.className}`
      });
    } else {
      throw props.icon;
    }
  } catch (err) {
    throw `Lucide icon '${props.icon}' not found.`;
  }
};

export default Lucide;
