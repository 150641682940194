import React, { Fragment } from 'react';
import { friendlyTimestamp } from '@/utils/friendly_timestamp';
import { IMessage } from '@/types/IMessage';

interface IHumanMessageProps {
  message: IMessage;
  photo: string;
}

const HumanMessage = ({ message, photo }: IHumanMessageProps) => {
  return (
    <Fragment>
      <div className='float-right mb-4 flex items-start last:mb-0'>
        <div>
          <div className='mb-1 rounded-lg rounded-tr-none border border-transparent bg-blue-500 p-3 text-sm text-white shadow-md'>{message.text}</div>
          <div className='flex items-center justify-end'>
            <div className='text-xs font-medium text-slate-500'>{friendlyTimestamp(message.timestamp)}</div>
          </div>
        </div>
        <img className='ml-4 rounded-full' src={photo} width='40' height='40' alt='User 02' />
      </div>
      <div className='clear-both'></div>
    </Fragment>
  );
};

export default HumanMessage;
