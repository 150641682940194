/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const agentBelongsToPartnerAccount = /* GraphQL */ `
  query AgentBelongsToPartnerAccount(
    $agentID: String
    $partnerAccountID: String
  ) {
    agentBelongsToPartnerAccount(
      agentID: $agentID
      partnerAccountID: $partnerAccountID
    ) {
      value
      statusCode
      __typename
    }
  }
`;
export const agentsByPartnerAccount = /* GraphQL */ `
  query AgentsByPartnerAccount($partnerAccountID: String) {
    agentsByPartnerAccount(partnerAccountID: $partnerAccountID) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      statusCode
      __typename
    }
  }
`;
export const getTokenKey = /* GraphQL */ `
  query GetTokenKey($id: ID!) {
    getTokenKey(id: $id) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
      __typename
    }
  }
`;
export const listTokenKeys = /* GraphQL */ `
  query ListTokenKeys(
    $filter: ModelTokenKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokenKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTokenKeys = /* GraphQL */ `
  query SyncTokenKeys(
    $filter: ModelTokenKeyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTokenKeys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      messages {
        nextToken
        startedAt
        __typename
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMessageTimestamp
      lastMessageText
      totalQuestionAmount
      usedQuestionAmount
      expirationDate
      usingPeriod
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        totalQuestionAmount
        usedQuestionAmount
        expirationDate
        usingPeriod
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatRooms = /* GraphQL */ `
  query SyncChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        totalQuestionAmount
        usedQuestionAmount
        expirationDate
        usingPeriod
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserToken = /* GraphQL */ `
  query GetUserToken($id: ID!) {
    getUserToken(id: $id) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userTokenUserId
      userTokenHistories {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserTokens = /* GraphQL */ `
  query ListUserTokens(
    $filter: ModelUserTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserTokens = /* GraphQL */ `
  query SyncUserTokens(
    $filter: ModelUserTokenFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserTokens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserTokenHistory = /* GraphQL */ `
  query GetUserTokenHistory($id: ID!) {
    getUserTokenHistory(id: $id) {
      id
      amount
      date
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userTokenID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserTokenHistories = /* GraphQL */ `
  query ListUserTokenHistories(
    $filter: ModelUserTokenHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTokenHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        date
        userTokenID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserTokenHistories = /* GraphQL */ `
  query SyncUserTokenHistories(
    $filter: ModelUserTokenHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserTokenHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        amount
        date
        userTokenID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGiftCard = /* GraphQL */ `
  query GetGiftCard($id: ID!) {
    getGiftCard(id: $id) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
      __typename
    }
  }
`;
export const listGiftCards = /* GraphQL */ `
  query ListGiftCards(
    $filter: ModelGiftCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiftCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGiftCards = /* GraphQL */ `
  query SyncGiftCards(
    $filter: ModelGiftCardFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGiftCards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amountInVND
        tokenAmount
        channel
        note
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPayments = /* GraphQL */ `
  query SyncPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        amountInVND
        tokenAmount
        channel
        note
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        totalQuestionAmount
        usedQuestionAmount
        expirationDate
        usingPeriod
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        timestamp
        promptTokens
        completionTokens
        isDefaultMessage
        ownerUserID
        userID
        chatRoomID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        text
        timestamp
        promptTokens
        completionTokens
        isDefaultMessage
        ownerUserID
        userID
        chatRoomID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
        __typename
      }
      chatRooms {
        nextToken
        startedAt
        __typename
      }
      giftCards {
        nextToken
        startedAt
        __typename
      }
      payments {
        nextToken
        startedAt
        __typename
      }
      tokenKeys {
        nextToken
        startedAt
        __typename
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userUserTokenId
      userTokenHistories {
        nextToken
        startedAt
        __typename
      }
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userPartnerAccountId
      userPartnerAccount {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userPartnerId
      userPartner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      apiKeys {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPartnerAccount = /* GraphQL */ `
  query GetPartnerAccount($id: ID!) {
    getPartnerAccount(id: $id) {
      id
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      partnerAccountUserId
      partnerAccountUser {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      agents {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPartnerAccounts = /* GraphQL */ `
  query ListPartnerAccounts(
    $filter: ModelPartnerAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPartnerAccounts = /* GraphQL */ `
  query SyncPartnerAccounts(
    $filter: ModelPartnerAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPartnerAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($id: ID!) {
    getAgent(id: $id) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
        __typename
      }
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      partnerAccounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAgents = /* GraphQL */ `
  query SyncAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAgents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      address
      website
      logo
      description
      status
      apiKeys {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      partnerUserId
      partnerUser {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      agents {
        nextToken
        startedAt
        __typename
      }
      partnerAccounts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPartners = /* GraphQL */ `
  query SyncPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getApiKey = /* GraphQL */ `
  query GetApiKey($id: ID!) {
    getApiKey(id: $id) {
      id
      name
      key
      status
      requestsPerMonth
      requestsPerHour
      partnerID
      partner {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listApiKeys = /* GraphQL */ `
  query ListApiKeys(
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApiKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        status
        requestsPerMonth
        requestsPerHour
        partnerID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncApiKeys = /* GraphQL */ `
  query SyncApiKeys(
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncApiKeys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        key
        status
        requestsPerMonth
        requestsPerHour
        partnerID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAgentPartnerAccounts = /* GraphQL */ `
  query GetAgentPartnerAccounts($id: ID!) {
    getAgentPartnerAccounts(id: $id) {
      id
      partnerAccountID
      agentID
      partnerAccount {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      agent {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAgentPartnerAccounts = /* GraphQL */ `
  query ListAgentPartnerAccounts(
    $filter: ModelAgentPartnerAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentPartnerAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerAccountID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAgentPartnerAccounts = /* GraphQL */ `
  query SyncAgentPartnerAccounts(
    $filter: ModelAgentPartnerAccountsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAgentPartnerAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerAccountID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const chatRoomByName = /* GraphQL */ `
  query ChatRoomByName(
    $chatRoomName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomByName(
      chatRoomName: $chatRoomName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        totalQuestionAmount
        usedQuestionAmount
        expirationDate
        usingPeriod
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const chatRoomsByUser = /* GraphQL */ `
  query ChatRoomsByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        totalQuestionAmount
        usedQuestionAmount
        expirationDate
        usingPeriod
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userTokenByUser = /* GraphQL */ `
  query UserTokenByUser(
    $userTokenUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTokenByUser(
      userTokenUserId: $userTokenUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userTokenHistoryByUserToken = /* GraphQL */ `
  query UserTokenHistoryByUserToken(
    $userTokenID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserTokenHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTokenHistoryByUserToken(
      userTokenID: $userTokenID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        date
        userTokenID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userTokenHistoryByUser = /* GraphQL */ `
  query UserTokenHistoryByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserTokenHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTokenHistoryByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        date
        userTokenID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const chatRoomMessages = /* GraphQL */ `
  query ChatRoomMessages(
    $chatRoomID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomMessages(
      chatRoomID: $chatRoomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        timestamp
        promptTokens
        completionTokens
        isDefaultMessage
        ownerUserID
        userID
        chatRoomID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userByUserToken = /* GraphQL */ `
  query UserByUserToken(
    $userUserTokenId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUserToken(
      userUserTokenId: $userUserTokenId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByPartner = /* GraphQL */ `
  query UsersByPartner(
    $partnerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByPartner(
      partnerID: $partnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userByUserPartnerAccount = /* GraphQL */ `
  query UserByUserPartnerAccount(
    $userPartnerAccountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUserPartnerAccount(
      userPartnerAccountId: $userPartnerAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const partnerUserByUserPartner = /* GraphQL */ `
  query PartnerUserByUserPartner(
    $userPartnerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerUserByUserPartner(
      userPartnerId: $userPartnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        userUserTokenId
        partnerID
        userPartnerAccountId
        userPartnerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const partnerAccountByPartner = /* GraphQL */ `
  query PartnerAccountByPartner(
    $partnerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerAccountByPartner(
      partnerID: $partnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const partnerAccountByUser = /* GraphQL */ `
  query PartnerAccountByUser(
    $partnerAccountUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerAccountByUser(
      partnerAccountUserId: $partnerAccountUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerID
        partnerAccountUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const agentByName = /* GraphQL */ `
  query AgentByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agentByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const agentsByPartner = /* GraphQL */ `
  query AgentsByPartner(
    $partnerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agentsByPartner(
      partnerID: $partnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        partnerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const partnerByPartnerUser = /* GraphQL */ `
  query PartnerByPartnerUser(
    $partnerUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerByPartnerUser(
      partnerUserId: $partnerUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        address
        website
        logo
        description
        status
        partnerUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const apiKeyByKey = /* GraphQL */ `
  query ApiKeyByKey(
    $key: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeyByKey(
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        key
        status
        requestsPerMonth
        requestsPerHour
        partnerID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const apiKeysByPartner = /* GraphQL */ `
  query ApiKeysByPartner(
    $partnerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeysByPartner(
      partnerID: $partnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        key
        status
        requestsPerMonth
        requestsPerHour
        partnerID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const apiKeysByUser = /* GraphQL */ `
  query ApiKeysByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeysByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        key
        status
        requestsPerMonth
        requestsPerHour
        partnerID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
