import moment from 'moment';

// Create current timestamp with UTC timezone, ISO 8601 format
export const currentTimestampString = () => {
  return new Date().toISOString();
};

export const friendlyTimestamp = (timestamp: string) => {
  // For showing a friendly timestamp, we want to show the time relative to now.
  // This is used for showing how long ago a message was sent or received.
  const now = moment();
  const then = moment(timestamp, moment.ISO_8601);
  const diff = now.diff(then, 'seconds');
  if (diff < 60) {
    return `Just now`;
  } else if (diff < 3600) {
    return `${Math.floor(diff / 60)}m`;
  } else if (diff < 86400) {
    return `${Math.floor(diff / 3600)}h`;
  } else if (diff < 604800) {
    return `${Math.floor(diff / 86400)}d`;
  } else if (diff < 2419200) {
    return `${Math.floor(diff / 604800)}w`;
  } else {
    return `${then.format('DD MMMM YYYY')}`;
  }
};
