import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/stores/redux/hooks';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { Auth } from '@aws-amplify/auth';
import { ChatRoomSubscription } from '@/utils/graphql/chat_room_utils';
import { MessageSubscription } from '@/utils/graphql/message_utils';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { Hub } from 'aws-amplify';
import { gqlFetchAllAgentsAndDispatch } from '@/utils/graphql/agent_utils';
import Header from '@/components/Header';
import { Modal } from '@/base-components/modal';
import SignInForm from '@/components/SignInForm';

function SideMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSignedIn, user, isDeviceRemembered, updateDeviceKey, isAdmin } = useAuth();
  const dispatch = useAppDispatch();
  const currentChatRoomID = useAppSelector((state) => state.chat.chatRoom.id);
  const [showDialog, setShowDialog] = useState(!isSignedIn);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isSignedIn) {
      navigate('/oauth/signin');
    }
  }, [isSignedIn, navigate]);

  useEffect(() => {
    document.body.classList.toggle('modal-open', showDialog);
  }, [showDialog]);

  useEffect(() => {
    if (isSignedIn) {
      setShowDialog(false);
    }
  }, [isSignedIn]);

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node) && isSignedIn) {
      setShowDialog(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSignedIn]);

  // Adjust admin view
  useEffect(() => {
    if (!dispatch || !user) return;

    // (async () => {
    //   const isUserAdmin = await isAdmin();
    //   if (isUserAdmin) {
    //     dispatch(
    //       sideMenuActions.adjustAdminView({
    //         icon: 'Settings',
    //         title: 'Admin',
    //         pathname: '/admin/token'
    //       })
    //     );
    //   }
    // })();
  }, [dispatch, isAdmin, user]);

  // When user is signed in, query and subscribe to the database
  // Also remember the device if required
  useEffect(() => {
    if (!isSignedIn || !user) {
      console.log('User is not signed in');
      return;
    } else {
      console.log('User is signed in');
    }

    // Chat room subscription
    let chatRoomSubscription: ChatRoomSubscription | null = null;
    let listener: ReturnType<typeof Hub.listen> | null = null;

    (async () => {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const isUserAdmin = await isAdmin();

      // Listen to API events
      listener = Hub.listen('api', (data) => {
        const { payload } = data;
        // TODO (MinhLuan): if there is some sudden disconnection, we need to reload the data
        if (payload.event === CONNECTION_STATE_CHANGE) {
          console.log('API event: ', payload.data.connectionState as ConnectionState);
        }
      });

      // Get cached device key
      cognitoUser.getCachedDeviceKeyAndPassword();

      if (isDeviceRemembered) {
        // Remember device
        cognitoUser.setDeviceStatusRemembered({
          onSuccess: (result: string) => {
            console.log('Device status remembered: ', result);
          },
          onFailure: (err: any) => {
            console.error('Error remembering device status: ', err);
          }
        });
      } else {
        // Forget device
        cognitoUser.setDeviceStatusNotRemembered({
          onSuccess: (result: string) => {
            console.log('Device status not remembered: ', result);
          },
          onFailure: (err: any) => {
            console.error('Error forgetting device status: ', err);
          }
        });
      }

      // Update device ID
      updateDeviceKey(cognitoUser.deviceKey);

      // Fetch all agents and dispatch to redux
      await gqlFetchAllAgentsAndDispatch(dispatch);

      // Subscribe to chat rooms
      chatRoomSubscription = new ChatRoomSubscription(dispatch, user.id, cognitoUser.deviceKey);
      await chatRoomSubscription.subscribe();

      // // Subscribe to users for admin user only
      // if (isUserAdmin) {
      //   await getAndDispatchAllUsersWithToken(dispatch);
      // }

      // Unsubscribe when component unmounts
      return () => {
        if (chatRoomSubscription) {
          chatRoomSubscription.unsubscribe();
        }

        if (listener) {
          // Remove listener
          if (listener) listener();
        }
      };
    })();
  }, []);

  // Query and dispatch messages when chat room changes
  useEffect(() => {
    let messagesSubscription: MessageSubscription | null = null;

    (async () => {
      if (!currentChatRoomID) {
        return;
      }

      console.log('Chat room changed: ', currentChatRoomID, ', dispatching messages...');

      // Subscribe and dispatch messages
      messagesSubscription = new MessageSubscription(dispatch, currentChatRoomID, user!.id);
      await messagesSubscription.subscribe();
    })();

    return () => {
      if (messagesSubscription) {
        messagesSubscription.unsubscribe();
      }
    };
  }, [currentChatRoomID, dispatch]);

  return (
    <div className='w-screen h-full bg-sky-200 dark:bg-slate-900'>
      <Header />

      {showDialog && (
        <Modal size='modal-lg' show={showDialog} backdrop='static'>
          <SignInForm onShowDialogChange={setShowDialog} />
        </Modal>
      )}
      <div className='content'>
        <Outlet />
      </div>
    </div>
  );
}

export default SideMenu;
