import { createContext, createElement, createRef, useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import '@left4code/tw-starter/dist/js/dropdown';
import PropTypes from 'prop-types';
import dom from '@left4code/tw-starter/dist/js/dom';
import { useLocation } from 'react-router-dom';

const init = (el, props) => {
  // eslint-disable-next-line no-undef
  const dropdown = tailwind.Dropdown.getOrCreateInstance(el);
  setTimeout(() => {
    const isDropdownShowed = dom(el).find('[data-dropdown-replacer]').length;
    if (props.show && !isDropdownShowed) {
      dropdown.show();
    } else if (!props.show && isDropdownShowed) {
      dropdown.hide();
    }
  });

  if (el['__initiated'] === undefined) {
    el['__initiated'] = true;

    el.addEventListener('show.tw.dropdown', () => {
      props.onShow();
    });

    el.addEventListener('shown.tw.dropdown', () => {
      props.onShown();
    });

    el.addEventListener('hide.tw.dropdown', () => {
      props.onHide();
    });

    el.addEventListener('hidden.tw.dropdown', () => {
      props.onHidden();
    });
  }
};

// DropDown wrapper
const dropdownRefContext = createContext(undefined);

const DropDown = (props) => {
  const dropdownRef = createRef();
  const dropdownRefTemp = createRef();
  const location = useLocation();

  // On prop change
  useEffect(() => {
    dropdownRefTemp.current = dropdownRef.current;
    init(dropdownRef.current, props);

    // Hide dropdown when component unmount
    return () => {
      // eslint-disable-next-line no-undef
      tailwind.Dropdown.getOrCreateInstance(dropdownRefTemp.current).hide();
    };
  }, [props.show, location, dropdownRefTemp, dropdownRef, props]);

  return createElement(
    dropdownRefContext.Provider,
    {
      value: dropdownRef
    },
    createElement(
      'div',
      {
        className: `dropdown ${props.className}`,
        ref: dropdownRef,
        'data-tw-placement': props.placement
      },
      typeof props.children === 'function'
        ? props.children({
            dismiss: () => {
              // eslint-disable-next-line no-undef
              tailwind.Dropdown.getOrCreateInstance(dropdownRef.current).hide();
            }
          })
        : props.children
    )
  );
};

DropDown.propTypes = {
  show: PropTypes.bool,
  placement: PropTypes.string,
  onShow: PropTypes.func,
  onShown: PropTypes.func,
  onHide: PropTypes.func,
  onHidden: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any
};

DropDown.defaultProps = {
  show: false,
  placement: 'bottom-end',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onShow: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onShown: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onHide: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onHidden: () => {}
};

// DropDown toggle
const DropdownToggle = (props) => {
  const { tag, href, ...computedProps } = props;
  return createElement(
    props.tag,
    {
      ...computedProps,
      className: `dropdown-toggle cursor-pointer ${props.className}`,
      'aria-expanded': false,
      'data-tw-toggle': 'dropdown'
    },
    props.children
  );
};

DropdownToggle.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  role: PropTypes.string,
  href: PropTypes.string
};

DropdownToggle.defaultProps = {
  tag: 'button'
};

// DropDown menu
const DropdownMenu = (props) => {
  const dropdownRef = useContext(dropdownRefContext);
  const dropdownMenuRef = createRef();

  useEffect(() => {
    dom(dropdownMenuRef.current).appendTo(dropdownRef.current);
  }, [dropdownRef]);

  return createPortal(
    createElement(
      'div',
      {},
      createElement(
        'div',
        {
          className: `dropdown-menu ${props.className}`,
          ref: dropdownMenuRef
        },
        props.children
      )
    ),
    dom('body')[0]
  );
};

// DropDown content
const DropdownContent = (props) => {
  return createElement(
    props.tag,
    {
      className: `dropdown-content ${props.className}`
    },
    props.children
  );
};

DropdownContent.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};

DropdownContent.defaultProps = {
  tag: 'ul'
};

// DropDown item
const DropdownItem = (props) => {
  const { tag, className, ...computedProps } = props;
  return createElement(
    'li',
    {
      ...computedProps
    },
    createElement(
      props.tag,
      {
        className: `dropdown-item cursor-pointer ${props.className}`
      },
      props.children
    )
  );
};

DropdownItem.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

DropdownItem.defaultProps = {
  tag: 'a',
  className: ''
};

// DropDown header
const DropdownHeader = (props) => {
  const { tag, className, ...computedProps } = props;
  return createElement(
    'li',
    {
      ...computedProps
    },
    createElement(
      props.tag,
      {
        className: `dropdown-header ${props.className}`
      },
      props.children
    )
  );
};

DropdownHeader.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

DropdownHeader.defaultProps = {
  tag: 'h6',
  className: ''
};

// DropDown footer
const DropdownFooter = (props) => {
  const { tag, className, ...computedProps } = props;
  return createElement(
    'li',
    {
      ...computedProps
    },
    createElement(
      props.tag,
      {
        className: `dropdown-footer ${props.className}`
      },
      props.children
    )
  );
};

DropdownFooter.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string
};

DropdownFooter.defaultProps = {
  tag: 'div',
  className: ''
};

// DropDown divider
const DropdownDivider = (props) => {
  return createElement(
    'li',
    {},
    createElement(
      props.tag,
      {
        className: `dropdown-divider ${props.className}`
      },
      props.children
    )
  );
};

DropdownDivider.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string
};

DropdownDivider.defaultProps = {
  tag: 'hr',
  className: ''
};

export { DropDown, DropdownToggle, DropdownMenu, DropdownContent, DropdownItem, DropdownHeader, DropdownFooter, DropdownDivider };
